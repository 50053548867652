import { makeStyles } from "tss-react/mui";
import { rootTheme } from "../../../../shared/theme";

const useStyles = makeStyles()({
  cardWrapper: {
    marginTop: 30,
  },
  container: {
    ...rootTheme.viewsContainer,
  },
  outerGrid: {
    height: "100%",
  },
  qrCode: {
    border: "2px solid black",
    width: "fit-content",
    padding: 5,
  },
});

export default useStyles;
