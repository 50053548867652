import { EApiState } from "../../../../../../api";
import { useCallback, useState } from "react";

export function useDoneMutation(): {
  state: EApiState;
  doneMutation: (serviceOrderId: string, version: string) => void;
} {
  const [state, setState] = useState(EApiState.OK);
  const doneMutation = useCallback(
    (serviceOrderId: string, version: string) => {
      setState(EApiState.LOADING);
      const t = setTimeout(() => {
        alert("done: " + serviceOrderId + " " + version);
        setState(EApiState.OK);
      }, 600);

      return () => clearTimeout(t);
    },
    [],
  );
  return { state, doneMutation };
}
