import React, { useMemo } from "react";
import { IList } from "../../../../../../shared/components";
import useStyles from "./styles";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import WithToggleableTooltip from "../../../../../withToggableTooltip";

interface IProps {
  isActive: boolean;
  label: string;
  routePath: string;
  icon?: React.ReactElement;
  isOpen: boolean;
  isSubItem?: boolean;
}

export default function SimpleEntry({
  isActive,
  routePath,
  icon,
  label,
  isOpen,
  isSubItem,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  const listItemStyles = {
    label: classes.label,
    listItem: `${classes.listItem} ${
      isSubItem && isOpen ? classes.SubListItem : ""
    }`,
    hiddenIcon: classes.hiddenIcon,
    simpleListItem:
      isSubItem && isOpen
        ? [classes.simpleSubListItem, classes.baseListItem].join(" ")
        : classes.baseListItem,
  };

  const listItemActiveStyles = {
    label: classes.label,
    listItem: classes.listItemActive,
    hiddenIcon: classes.hiddenIconActive,
    simpleListItem:
      isSubItem && isOpen
        ? [classes.simpleSubListItem, classes.baseListItem].join(" ")
        : classes.baseListItem,
  };
  const iconMinWidth = useMemo(() => {
    return isOpen ? 56 : 30;
  }, [isOpen]);
  return (
    <WithToggleableTooltip
      label={label}
      smallTooltip={true}
      forceEnable={!isOpen}
      placement="right"
    >
      <IList
        showIcon={icon != null}
        // actually, this is a hack, making the text align centric.
        icon={icon ? icon : <FullscreenIcon color="primary" />}
        label={label}
        labelVariant="body1"
        route={routePath}
        styles={isActive ? listItemActiveStyles : listItemStyles}
        iconToLabelSpace={iconMinWidth}
        isCollapsed={!isOpen}
        isOpen={isActive}
      />
    </WithToggleableTooltip>
  );
}
