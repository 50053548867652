import React, { useCallback, useEffect, useMemo, useState } from "react";
import useStyles from ".././styles";
import HeatmapRow from "./components/heatmapRow";
import IHeatMapRow from "./components/heatmapRow/api/IHeatMapRow";
import IConfigElement from "../../../../config/states/IIndicatorConfigElement";
import { TScaleValueFromServer } from "../../../../components/charts/machineStateHeatmapCard/api/transformation/calculateStartEndDateOffset/calculateStartEndDateOffset";
import HeatmapXAxis from "./components/heatmapXaxis";
import { useCurrentPng } from "recharts-to-png";
import FileSaver from "file-saver";
import BarChartHeatMapSkeleton from "./components/skeleton";
import HeatmapXAxisSkeleton from "./components/heatmapXaxis/skeleton";
import { IExportEnum } from "../../../../components/charts/components/exportButton";
import generateFileName from "../../../../services/generateFileName";
import { useTranslation } from "react-i18next";
import getIndicatorTranslationByStateName from "../../../../helper/indicator/getIndicatorTranslationByStateName";
import formatDateToString from "../../../../helper/time/formatting/formatDateToString";
import getTimezoneOffset from "../../../../helper/time/formatting/getTimezoneOffset";

interface IProps {
  rows: IHeatMapRow[];
  config: IConfigElement[];
  scale?: TScaleValueFromServer;
  isStacked?: boolean;
  locale?: string;
  timezone?: string;
  isLoading?: boolean;
  triggerExport: IExportEnum;
  onExportHandled: () => void;
  hiddenTooltipDataKeys?: string[];
  machineId: string;
}
function BarChartHeatMap({
  rows,
  config,
  scale,
  isStacked = false,
  locale = "en",
  timezone = "UTC",
  isLoading = false,
  triggerExport,
  onExportHandled,
  hiddenTooltipDataKeys,
  machineId,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const [activeTooltipIndex, setActiveTooltipIndex] = useState<number>(-1);
  const handleMouseOver = (index) => {
    setActiveTooltipIndex(index);
  };
  const handleMouseLeave = (index) => {
    if (index === activeTooltipIndex) {
      setActiveTooltipIndex(-1);
    }
  };
  // Calculate the height of each AreaChart
  const initializedRows = useMemo(
    () => (isLoading ? Array(4).fill({}) : rows),
    [rows, isLoading],
  );
  const chartHeight = useMemo(
    () => `calc((100% - ${30}px) / ${initializedRows.length})`,
    [initializedRows.length, isLoading],
  );
  useEffect(() => {
    switch (triggerExport) {
      case IExportEnum.PNG:
        handleExportPNG();
        break;
      case IExportEnum.CSV:
        handleExportCSV();
        break;
      default:
        onExportHandled();
        break;
    }
  }, [triggerExport]);

  // useCurrentPng usage (isLoading is optional)
  const [getLinePng, { ref: chartRef }] = useCurrentPng();
  // TODO: use a suitable ExportPNG Library instead of recharts-to-png because it is not working on multi-chart component
  const handleExportPNG = useCallback(async () => {
    const png = await getLinePng();

    // Verify that png is not undefined
    if (png) {
      // Download with FileSaver
      const fileName = generateFileName(
        machineId,
        locale,
        t,
        timezone,
        "Chart",
        IExportEnum.PNG,
      );
      FileSaver.saveAs(png, fileName);
    }
    if (onExportHandled) {
      onExportHandled();
    }
  }, [getLinePng]);

  // get csv data from chart data
  const converDataToCSV = useCallback(() => {
    if (!rows || rows.length === 0) return "";

    // Extract unique indicator IDs.
    const uniqueIndicatorIds = Array.from(
      new Set(
        rows.flatMap((row) =>
          row.layers.map((layer) => layer.indicatorIdentificationTypeId),
        ),
      ),
    );

    // Map IDs to names.
    const indicatorMap = uniqueIndicatorIds.reduce((acc, id) => {
      const indicator = config.find((conf) => parseInt(conf.id) === id);
      if (indicator) {
        const translatedName = getIndicatorTranslationByStateName(
          indicator.displayName,
          t,
        ).name;
        acc.set(id, translatedName);
      }
      return acc;
    }, new Map<number, string>());

    const timezoneOffset = getTimezoneOffset(timezone);

    // Create CSV header.
    const headers = [
      `${t("dashboard.commonChart.startDate")}(${timezoneOffset})`,
      `${t("dashboard.commonChart.endDate")}(${timezoneOffset})`,
      "State",
    ].join("; ");

    // Extract and format data rows.
    const data = rows
      .flatMap((entry) =>
        entry.layers.flatMap((layer) =>
          layer.parts
            .filter((part) => part.dateStart && part.dateEnd)
            .map((part) => {
              return {
                start: part.dateStart,
                end: part.dateEnd,
                indicator: indicatorMap.get(
                  layer.indicatorIdentificationTypeId,
                ),
              };
            }),
        ),
      )
      .sort(
        (a, b) =>
          new Date(a.start ?? 0).getTime() - new Date(b.start ?? 0).getTime(),
      )
      .map((entry) =>
        [
          formatDateToString(
            new Date(entry.start ?? 0),
            locale,
            t,
            "fulldatetime",
            "DAYS",
            timezone,
          ),
          formatDateToString(
            new Date(entry.end ?? 0),
            locale,
            t,
            "fulldatetime",
            "DAYS",
            timezone,
          ),
          entry.indicator,
        ].join("; "),
      );

    return [headers, ...data].join("\n");
  }, [rows]);

  const handleExportCSV = useCallback(async () => {
    const convertedCSV = converDataToCSV();
    if (!convertedCSV) {
      onExportHandled();
      return;
    }
    const blob = new Blob([convertedCSV], { type: "text/csv;charset=utf-8" });
    const fileName = generateFileName(
      machineId,
      locale,
      t,
      timezone,
      "Chart",
      IExportEnum.CSV,
    );
    FileSaver.saveAs(blob, fileName);
    if (onExportHandled) {
      onExportHandled();
    }
  }, [rows]);

  return (
    <div key={"chart"} className={classes.chartContainer} ref={chartRef}>
      {initializedRows.map((row: IHeatMapRow, index) => {
        return (
          <div key={index} style={{ height: chartHeight }}>
            {isLoading ? (
              <BarChartHeatMapSkeleton
                label={`${index}`}
                key={row.label + index + "skeleton"}
              />
            ) : (
              <HeatmapRow
                showLabels={true}
                label={row.label}
                rowData={row}
                config={config}
                isStacked={isStacked}
                isActive={activeTooltipIndex === index}
                t={t}
                language={locale}
                timezone={timezone}
                key={row.label + index}
                onMouseOver={() => handleMouseOver(index)}
                onMouseLeave={() => handleMouseLeave(index)}
                index={index}
                hiddenTooltipDataKeys={hiddenTooltipDataKeys}
                scale={scale}
              />
            )}
          </div>
        );
      })}
      {isLoading ? (
        <HeatmapXAxisSkeleton />
      ) : (
        <HeatmapXAxis scale={scale ?? "days"} locale={locale} />
      )}
    </div>
  );
}

export default React.memo(BarChartHeatMap);
