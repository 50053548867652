import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  container: {
    marginLeft: 0,
    height: "100%",
    maxWidth: "none",
    width: "100%",
  },
  menu: {
    display: "block",
  },
  inputContainer: {
    width: "100%",
    "& .MuiInputBase-input": {
      height: "auto",
    },
  },
  textInput: {
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
  },
  rootInput: {
    width: "100%",
    backgroundColor: "#fff",
  },
  singleRootInput: {
    // width: "50%",
    backgroundColor: "#fff",
    "@media (max-width: 1500px)": {
      width: "100%",
    },
  },
  selectIcon: {
    marginRight: 10,
  },
}));

export default useStyles;
