import React from "react";
import { Grid, Link } from "@mui/material";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface IProps<T> {
  keyLabel: string;
  label: string;
  link: string;
  styles?: StyleObject;
  icon?: JSX.Element;
}

type StyleObject = {
  linkStyle?: string;
  gridStyle?: string;
};

export default function LinkCard<T>({
  keyLabel,
  label,
  link,
  styles = {
    linkStyle: "",
    gridStyle: "",
  },
  icon,
}: IProps<T>): React.ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  return (
    <Grid
      key={keyLabel}
      item
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      className={styles.gridStyle}
    >
      <>
        {
          <Link href={link} className={styles.linkStyle} target="_blank">
            {label}
          </Link>
        }
        {icon}
      </>
    </Grid>
  );
}
