import { makeStyles } from "tss-react/mui";
import { colors } from "../../../../shared/theme";

const useStyles = makeStyles()(() => ({
  box: {
    backgroundColor: colors.white_base,
    minHeight: 440,
    padding: 40,
  },
  boxExpanded: { width: 1540 },
  boxExpandedExtended: { width: 1700 },
  boxCompact: { width: 960 },
  boxCompactExtended: { width: 1120 },
  spinnerBox: {
    marginTop: 240,
    marginLeft: 432,
  },
  titleWrapper: {
    marginBottom: 24,
  },
  expandedButton: {
    backgroundColor: colors.georg_table_border_grey,
    border: "1px solid " + colors.whiteB_8,
    borderBottom: "0px",
    width: 84,
    height: 36,
  },
  expandButtonWrapper: {
    width: "100%",
    justifyContent: "flex-end",
    display: "flex",
    padding: "8px 0",
  },
  tableWithButtonWrapper: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default useStyles;
