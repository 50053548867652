import React from "react";
import Skeleton from "@mui/material/Skeleton";
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import LineChartLegendSkeleton from "../LineChartLegend/skeleton";
import { colors } from "../../../../../theme";

// Skeleton component for LineChart
interface CustomTickProps {
  x: number;
  y: number;
  payload: {
    value: string | number;
  };
}

const skeletonData = [
  { name: "A", value: 0 },
  { name: "B", value: 2 },
  { name: "C", value: 1 },
  { name: "D", value: 3 },
];

const CustomTickY = (props: CustomTickProps) => {
  const { x, y, payload } = props;
  const adjustedY = payload.value !== 0 ? y - 10 : y - 15;
  return (
    <g transform={`translate(${x - 35},${adjustedY})`}>
      <foreignObject width={30} height={20}>
        <Skeleton variant="text" width="100%" height="100%" animation="wave" />
      </foreignObject>
    </g>
  );
};

const CustomTickX = (props: CustomTickProps) => {
  const { x, y, payload } = props;
  const adjustedX =
    payload.value !== "A" ? (payload.value === "D" ? x - 30 : x - 14) : x;
  return (
    <g transform={`translate(${adjustedX},${y})`}>
      <foreignObject width={30} height={20}>
        <Skeleton variant="text" width="100%" height="100%" animation="wave" />
      </foreignObject>
    </g>
  );
};

const LineChartSkeleton: React.FC = () => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ResponsiveContainer>
        <LineChart
          data={skeletonData}
          margin={{ right: 30, left: 20, bottom: 20 }}
        >
          <XAxis
            dataKey="name"
            tickLine
            tick={CustomTickX}
            interval="preserveStartEnd"
            axisLine
          />
          <YAxis
            tickLine
            tick={CustomTickY}
            interval="preserveStartEnd"
            domain={[0, 4]}
          />
          <Line
            type="step"
            dataKey="value"
            stroke={colors.greyB40}
            strokeWidth={3}
            animationEasing="linear"
            isAnimationActive={false}
          />
          <Legend
            content={(legendData) => legendData && <LineChartLegendSkeleton />}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChartSkeleton;
