import React, { useMemo } from "react";
import { Typography } from "@mui/material";
import useStyles from "./styles";
import formatNumberString from "../../../../helper/valueRepresentation/formatNumberString";

interface IProps {
  value?: number;
  metric?: string;
}

function ShopfloorValueDisplay({ value, metric }: IProps): React.ReactElement {
  const { classes } = useStyles();

  const displayValue = useMemo(() => {
    if (typeof value === "undefined") {
      return "";
    }
    return formatNumberString(value.toString());
  }, [value]);

  return (
    <div className={classes.container}>
      <div style={{ display: "flex" }}>
        <Typography className={classes.valueContainer} color={"primary"}>
          {displayValue}
        </Typography>
        {metric != undefined && (
          <Typography
            color={"textSecondary"}
            className={classes.metricContainer}
          >{`${metric}`}</Typography>
        )}
      </div>
    </div>
  );
}
export default React.memo(ShopfloorValueDisplay);
