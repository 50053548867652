import { makeStyles } from "tss-react/mui";
import { colors, rootTheme } from "../../theme";

const useStyles = makeStyles()({
  container: {
    padding: 0,
    margin: 0,
    border: 0,
    //position: "relative",
    height: "100%",
    width: "100%",
  },
  Icontainer: {
    padding: 0,
    margin: 0,
    border: 0,
    //height: rootTheme.elementHeight,
    width: "100%",
    height: "100%",
  },
  chartContainer: {
    //paddingTop: rootTheme.elementHeight,
    height: `max(calc(100% - ${rootTheme.elementHeight}px), 80px)`, //`calc(100% - ${rootTheme.elementHeight}px)`, //400,
    //width: `max(100%)`,
    paddingRight: 16,
    margin: 0,
    border: 0,
    top: 0,
    position: "relative",
    "& .recharts-text": {
      fontFamily: "Titillium Web",
      fontColor: colors.blue_base,
    },
  },
  chartArea: {
    position: "relative",
    height: "100%",
    width: "100%",
    paddingTop: rootTheme.elementHeight,
    padding: 0,
    margin: 0,
    border: 0,
    verticalAlign: "top",
  },
  chart: {
    width: "100%",
  },
  legendContainer: {
    alignItems: "center",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "center",
  },
  legendColorBox: {
    height: 12,
    marginLeft: 12,
    marginRight: 8,
    width: 12,
  },
  legendLabel: {
    width: "100%",
    fontFamily: "Titillium Web",
  },
  legendWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  tooltipContainer: { padding: 20 },
  xAxisLabel: {
    ".recharts-text": {
      color: colors.blue_base,
      fontSize: 14,
      fontWeight: 300,
      fontFamily: "Titillium Web",
    },
  },
  yAxisLabel: {
    color: colors.blue_base,
    fontSize: 16,
    fontWeight: 300,
    writingMode: "sideways-lr",
    fontFamily: "Titillium Web",
  },
});

export default useStyles;
