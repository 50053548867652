import { TFunction } from "i18next";
import {
  GridCellParams,
  GridColDef,
  GridFilterItem,
  getGridStringOperators,
} from "@mui/x-data-grid";
import { Check, Delete, Edit, Undo, Cancel } from "@mui/icons-material";
import IconCell from "../../../../../../shared/components/georgDataGrid/components/iconCell";
import { TooltipCell } from "../../../../../../shared/components/georgDataGrid/components/tooltipCell";
import { Button } from "@mui/material";
import { ClassNameMap } from "@mui/material/styles";
import StateCell, {
  EDisplayState,
} from "../../../../../../shared/components/georgDataGrid/components/stateCell";
import formatDateToString from "../../../../../../helper/time/formatting/formatDateToString";
import {
  getPriorityKey,
  getPriorityFilter,
} from "../../../../../../shared/components/jobPrioritySelection/transformPriorityValue";
import { ColumnEnum, createTooltipText } from "./helper/getCellToolTip";
import {
  ECreatorType,
  jobCriticalState,
} from "../../../../../../api/jobs/interfaces/IJobSettings";
import resources from "../../../../../../@types/resources";
import {
  EJobPriorityKey,
  TJobPriorityKey,
} from "../../../../../../shared/components/jobPrioritySelection/components/jobPrioritySelection";
import CustomFilterComponent from "../../../../../../shared/components/georgDataGrid/components/customFilterComponent";

const defaultProps: Omit<GridColDef, "field"> = {
  headerAlign: "center",
  align: "center",
  sortable: true,
  type: "string",
  disableColumnMenu: true,
};

function tooltipTextCellRender(cellValue: string) {
  return (
    <TooltipCell tooltipText={cellValue}>
      <span>{cellValue}</span>
    </TooltipCell>
  );
}

type displayStateKeys =
  keyof typeof resources.taskManager.jobManagement.table.timeRemaining_field.tooltip.row;

export default function getColumnConfig(
  t: TFunction,
  lang: string,
  classes: ClassNameMap,
  onRemoveClick: (jobId: string) => void,
  onChangeDone: (jobId: string, newIsDone: boolean) => void,
  onEdit: (jobId: string) => void,
  onAbort: (jobId: string, newIsAborted: boolean) => void,
  isMutationLoading: boolean,
  timezone: string,
): GridColDef[] {
  const priorityList = [
    {
      value: EJobPriorityKey.LOW,
      label: t(
        `taskManager.jobManagement.table.priority_field.tooltip.row.low`,
      ),
    },
    {
      value: EJobPriorityKey.MIDDLE,
      label: t(
        `taskManager.jobManagement.table.priority_field.tooltip.row.middle`,
      ),
    },
    {
      value: EJobPriorityKey.HIGH,
      label: t(
        `taskManager.jobManagement.table.priority_field.tooltip.row.high`,
      ),
    },
    {
      value: EJobPriorityKey.URGENT,
      label: t(
        `taskManager.jobManagement.table.priority_field.tooltip.row.urgent`,
      ),
    },
  ];
  return [
    {
      ...defaultProps,
      field: "jobCriticalState",
      headerName: t("taskManager.jobManagement.table.timeRemaining_field.name"),
      flex: 1,
      filterable: true,
      renderHeader: () => (
        <TooltipCell
          tooltipText={
            t("taskManager.jobManagement.table.timeRemaining_field.name") || ""
          }
          fillContent={true}
        >
          <h3>
            {t("taskManager.jobManagement.table.timeRemaining_field.name")}
          </h3>
        </TooltipCell>
      ),
      sortComparator: (a, b) => {
        const valueA = a?.remainingInMillis || Number.NEGATIVE_INFINITY;
        const valueB = b?.remainingInMillis || Number.NEGATIVE_INFINITY;

        if (valueA < 0 && valueB >= 0) {
          return -1;
        } else if (valueB < 0 && valueA >= 0) {
          return 1;
        } else {
          return valueA - valueB;
        }
      },
      cellClassName: classes.noPadding,
      valueGetter: (value) => {
        const cellValue = value as jobCriticalState;
        return cellValue?.timeRemaining || "";
      },
      renderCell: (params: GridCellParams) => {
        const cellValue = params.row.jobCriticalState as jobCriticalState;
        const stateText =
          cellValue?.criticalityState ?? EDisplayState.UNDEFINED;
        return (
          <TooltipCell
            tooltipText={t(
              `taskManager.jobManagement.table.timeRemaining_field.tooltip.row.${
                stateText.toLowerCase() as displayStateKeys
              }`,
            )}
            fillContent={true}
          >
            <StateCell
              displayState={
                cellValue?.criticalityState ?? EDisplayState.UNDEFINED
              }
              pulse={
                Number.parseInt(cellValue?.remainingInMillis) < 0 &&
                cellValue?.criticalityState === EDisplayState.DANGER
              }
            >
              <span>{cellValue?.timeRemaining ?? "-"}</span>
            </StateCell>
          </TooltipCell>
        );
      },
    },
    {
      ...defaultProps,
      field: "priority",
      headerName: t("taskManager.jobManagement.table.priority_field.name"),
      flex: 1,
      filterable: true,
      renderHeader: () => (
        <TooltipCell
          tooltipText={
            t(
              "taskManager.jobManagement.table.priority_field.tooltip.column",
            ) || ""
          }
          fillContent={true}
        >
          <h3>{t("taskManager.jobManagement.table.priority_field.name")}</h3>
        </TooltipCell>
      ),
      cellClassName: classes.noPadding,
      valueGetter: (value) => {
        if (!value) {
          return "-";
        }
        const val = parseInt(value as unknown as string);
        return val;
      },
      filterOperators: [
        {
          label: t(
            "taskManager.jobManagement.table.priority_field.filter.byName",
          ),
          value: t(
            "taskManager.jobManagement.table.priority_field.filter.byName",
          ),
          getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.value) {
              return null;
            }
            return (value) => {
              const val = value as number;
              return getPriorityFilter(
                filterItem.value,
                parseInt(String(val ?? 0)),
              );
            };
          },
          InputComponent: (props) => (
            <CustomFilterComponent
              filterProps={props}
              filterList={priorityList}
            />
          ),
        },
        ...getGridStringOperators(),
      ],
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        const priorityState = params.row.priorityState as EDisplayState;
        const priorityKey = getPriorityKey(Number(cellValue));
        let tooltipText = "";
        if (priorityKey && (priorityKey as TJobPriorityKey) === priorityKey) {
          tooltipText = t(
            `taskManager.jobManagement.table.priority_field.tooltip.row.${priorityKey}`,
          );
        }
        return (
          <TooltipCell tooltipText={tooltipText} fillContent={true}>
            <StateCell displayState={priorityState}>
              <span>{cellValue}</span>
            </StateCell>
          </TooltipCell>
        );
      },
    },
    {
      ...defaultProps,
      field: "machineName",
      headerName: t("taskManager.jobManagement.table.machineName_field.name"),
      flex: 1,
      renderHeader: () => (
        <h3>{t("taskManager.jobManagement.table.machineName_field.name")}</h3>
      ),
      valueGetter: (_row, value) => {
        const { machineType } = value;
        return machineType ? machineType["id"] : null;
      },
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return tooltipTextCellRender(cellValue);
      },
    },
    {
      ...defaultProps,
      field: "name",
      headerName: t("taskManager.jobManagement.table.name_field.name"),
      hideable: true,
      flex: 1,
      renderHeader: () => (
        <h3>{t("taskManager.jobManagement.table.name_field.name")}</h3>
      ),
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return tooltipTextCellRender(cellValue);
      },
    },
    {
      ...defaultProps,
      field: "description",
      headerName: t("taskManager.jobManagement.table.description_field.name"),
      flex: 2,
      renderHeader: () => (
        <h3>{t("taskManager.jobManagement.table.description_field.name")}</h3>
      ),
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return tooltipTextCellRender(cellValue);
      },
    },
    {
      ...defaultProps,
      field: "scheduledAt",
      headerName: t("taskManager.jobManagement.table.scheduledAt_field.name"),
      hideable: true,
      flex: 1.5,
      filterable: true,
      renderHeader: () => (
        <h3>{t("taskManager.jobManagement.table.scheduledAt_field.name")}</h3>
      ),
      valueGetter: (_row, value) => {
        const { scheduledAt } = value;
        if (!scheduledAt) {
          return "-";
        }
        return formatDateToString(
          new Date(scheduledAt as string),
          lang,
          t,
          "datetime",
          "HOURS",
          timezone,
        );
      },
      renderCell: (params: GridCellParams) => {
        return tooltipTextCellRender(params.value as string);
      },
    },
    {
      ...defaultProps,
      field: "createdBy",
      headerName: t("taskManager.jobManagement.table.createdBy_field.name"),
      flex: 1.5,
      renderHeader: () => (
        <h3>{t("taskManager.jobManagement.table.createdBy_field.name")}</h3>
      ),
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as ECreatorType;
        const name = params.row.createdByName as EDisplayState;
        const displayedText =
          cellValue === ECreatorType.MACHINE ? cellValue : name;
        return tooltipTextCellRender(displayedText);
      },
    },
    {
      ...defaultProps,
      field: "isDone",
      hideable: true,
      headerName: t("taskManager.jobManagement.table.isDone_field.name"),
      filterable: false,
      sortable: false,
      flex: 0.8,
      minWidth: 60,
      renderHeader: () => (
        <h3>{t("taskManager.jobManagement.table.isDone_field.name")}</h3>
      ),
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        const wasDone = !!row.doneAt;
        const wasAborted = !!row.abortedAt;
        const wasDeleted = !!row.deletedAt;
        const tooltipObj = createTooltipText(params, t, ColumnEnum.Done);
        if (wasAborted && !wasDeleted) {
          return null;
        } else {
          return (
            <TooltipCell tooltipText={tooltipObj.tooltipLabel}>
              <Button
                className={classes.doneButton}
                variant="contained"
                disabled={isMutationLoading || wasDeleted}
                onClick={() => {
                  onChangeDone(row.jobId, !wasDone);
                }}
              >
                {wasDone ? <Undo /> : <Check />}
              </Button>
            </TooltipCell>
          );
        }
      },
    },
    {
      ...defaultProps,
      field: "abort",
      hideable: true,
      headerName: t("taskManager.jobManagement.table.abort_field.name"),
      filterable: false,
      sortable: false,
      flex: 0.8,
      minWidth: 60,
      renderHeader: () => (
        <h3>{t("taskManager.jobManagement.table.abort_field.name")}</h3>
      ),
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        const wasDone = !!row.doneAt;
        const wasAborted = !!row.abortedAt;
        const wasDeleted = !!row.deletedAt;
        const abortMessage = params.row.abortMessage;
        const tooltipObj = createTooltipText(params, t, ColumnEnum.Abort);
        if (wasDone && !wasDeleted) {
          return null;
        } else {
          return (
            <TooltipCell
              tooltipText={tooltipObj.tooltipLabel}
              description={
                tooltipObj.tooltipDescription.length === 0
                  ? ""
                  : tooltipObj.tooltipDescription + (abortMessage ?? "")
              }
            >
              <Button
                className={classes.doneButton}
                variant="contained"
                disabled={isMutationLoading || wasDeleted}
                onClick={() => {
                  onAbort(row.jobId, !wasAborted);
                }}
              >
                {wasAborted ? <Undo /> : <Cancel />}
              </Button>
            </TooltipCell>
          );
        }
      },
    },
    {
      ...defaultProps,
      field: "edit",
      hideable: true,
      filterable: false,
      headerName: t("taskManager.jobManagement.table.edit_field.name"),
      sortable: false,
      flex: 0.8,
      minWidth: 70,
      renderHeader: () => (
        <h3>{t("taskManager.jobManagement.table.edit_field.name")}</h3>
      ),
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        const wasDone = !!row.doneAt;
        const wasAborted = !!row.abortedAt;
        const wasDeleted = !!row.deletedAt;
        const tooltipObj = createTooltipText(params, t, ColumnEnum.Edit);
        return (
          <TooltipCell tooltipText={tooltipObj.tooltipLabel}>
            <IconCell
              disabled={
                isMutationLoading || wasDone || wasAborted || wasDeleted
              }
              onClick={() => {
                onEdit(row.jobId);
              }}
              icon={Edit}
            />
          </TooltipCell>
        );
      },
    },
    {
      ...defaultProps,
      field: "remove",
      hideable: true,
      headerName: t("taskManager.jobManagement.table.delete_field.name"),
      filterable: false,
      sortable: false,
      flex: 0.8,
      minWidth: 70,
      renderHeader: () => (
        <h3>{t("taskManager.jobManagement.table.delete_field.name")}</h3>
      ),
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        const wasDeleted = row.deletedAt != null;
        const wasDone = !!row.doneAt;
        const wasAborted = !!row.abortedAt;
        const tooltipText = wasDeleted
          ? t(
              "taskManager.jobManagement.table.delete_field.tooltip.row.deleteDisabled",
            ) || ""
          : t(
              "taskManager.jobManagement.table.delete_field.tooltip.row.delete",
            ) || "";
        return (
          <TooltipCell tooltipText={tooltipText}>
            <IconCell
              disabled={
                isMutationLoading || wasDeleted || wasDone || wasAborted
              }
              onClick={() => {
                onRemoveClick(row.jobId);
              }}
              icon={Delete}
            />
          </TooltipCell>
        );
      },
    },
  ];
}
