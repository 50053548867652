/*
 *
 *   Downtime Reasons view for machine dashboard.
 *
 */

import React, { useMemo } from "react";
import GridPage from "../../../../../shared/components/gridPage";
import getLayoutInfo, {
  BAR_CHART_LAYOUT_KEY,
  HEATMAP_LAYOUT_KEY,
  TResizeConfig,
} from "./layoutConfig";
import MachineStateBarChartStacked from "../../../../../components/charts/machineStateBarChartStacked";
import MachineStateHeatmapCard from "../../../../../components/charts/machineStateHeatmapCard";
import { useSelector } from "react-redux";
import { selectMachine } from "../../../../../redux/reducers/machineSelectReducer";
import { getSelectedMachineDowntimeList } from "../../../../../redux/reducers/downtimeReducer";

export default function DowntimeReasonsView(): React.ReactElement {
  const selectedMachine = useSelector(selectMachine);
  const indicatorIds: string[] = useSelector(
    getSelectedMachineDowntimeList(selectedMachine),
  );

  const layoutConf = useMemo(() => getLayoutInfo({} as TResizeConfig), []);

  return (
    <GridPage layouts={layoutConf}>
      <div key={BAR_CHART_LAYOUT_KEY}>
        <MachineStateBarChartStacked indicatorIds={indicatorIds} />
      </div>
      <div key={HEATMAP_LAYOUT_KEY}>
        <MachineStateHeatmapCard indicatorIds={indicatorIds} />
      </div>
    </GridPage>
  );
}
