import { useEffect, useState } from "react";
import {
  IMachineWithServiceOrderSettings,
  getMachinesForMachineId,
} from "../../api";
import { useSelector } from "react-redux";
import { selectMachineList } from "../../../../../../redux/reducers/machineListReducer";
import { IMachine } from "../../../../../../api/machine/IMachine";
import { EApiState } from "../../../../../../api";

function useMockedMachineWithServiceOrders(
  machineIds: string[],
): IMachineWithServiceOrderSettings[] {
  const allMachines: IMachine[] = useSelector(selectMachineList);
  return getMachinesForMachineId(machineIds, allMachines);
}

export function useQueryMachinesWithServiceOrders(machineIds: string[]): {
  machinesWithServiceOrders: IMachineWithServiceOrderSettings[];
  apiState: EApiState;
} {
  const mockData = useMockedMachineWithServiceOrders(machineIds);
  const [apiState, setApiState] = useState(EApiState.LOADING);
  const [serviceOrders, setServiceOrders] = useState<
    IMachineWithServiceOrderSettings[]
  >([]);
  useEffect(() => {
    setApiState(EApiState.LOADING);
    const t = setTimeout(() => {
      setServiceOrders(mockData);
      setApiState(EApiState.OK);
    }, 600);
    return () => {
      clearTimeout(t);
    };
  }, [machineIds]);
  return { apiState, machinesWithServiceOrders: serviceOrders };
}
