/*
 *
 *   Higher order component for wrapping components with a tooltip.
 *
 */

import React from "react";
import { Tooltip, Typography } from "@mui/material";
import useStyles from "./styles";
export type TPlacement =
  | "bottom-end"
  | "bottom-start"
  | "bottom"
  | "left-end"
  | "left-start"
  | "left"
  | "right-end"
  | "right-start"
  | "right"
  | "top-end"
  | "top-start"
  | "top";

//------------------------------------------------------------------------------------------------------------
// Interfaces & Types.
interface Props {
  children: JSX.Element;
  description?: string;
  label?: string;
  sublabel?: string;
  footer?: string;
  smallTooltip?: boolean;
  centerContent?: boolean;
  noOverflow?: boolean;
  placement?: TPlacement;
  isFullWidthContainer?: boolean;
}

/*
  TODO React Tooltip findDomNode is deprecated Warning will be fixed with Material UI Version 5
 */

//------------------------------------------------------------------------------------------------------------
// Component.
export default function WithTooltip({
  children,
  description,
  label,
  sublabel,
  footer,
  smallTooltip = false,
  centerContent = false,
  noOverflow = false,
  placement = "right-end",
  isFullWidthContainer = false,
}: Props): React.ReactElement {
  const { classes } = useStyles();
  const key = label
    ? label
    : "" + sublabel
      ? sublabel
      : "" + description
        ? description
        : "";
  const renderInfoTooltip = () => {
    return (
      <Tooltip
        key={key}
        arrow
        // disableInteractive
        title={
          <>
            <Typography color="primary" variant="h2">
              {label}
            </Typography>
            <Typography
              className={classes.sublabel}
              color="primary"
              variant="caption"
            >
              {sublabel}
            </Typography>
            <Typography
              className={classes.description}
              color="primary"
              variant="body1"
            >
              {description}
            </Typography>
            <Typography
              className={classes.footer}
              color="primary"
              variant="caption"
            >
              {footer}
            </Typography>
          </>
        }
        placement={placement}
        classes={{
          arrow: classes.arrow,
          tooltip: classes.tooltip,
        }}
      >
        <div
          className={`${centerContent ? classes.tooltip_wrapper : ""}
          ${isFullWidthContainer ? classes.fullWidth : ""} ${
            noOverflow ? classes.no_overflow : ""
          }`}
        >
          {children}
        </div>
      </Tooltip>
    );
  };

  const renderSmallTooltip = () => {
    return (
      <Tooltip
        key={key}
        arrow
        disableInteractive
        title={
          <Typography color="primary" variant="body2">
            {label}
          </Typography>
        }
        placement={placement}
        classes={{
          arrow: classes.arrow,
          tooltip: classes.smallTooltip,
        }}
      >
        <div
          className={`${centerContent ? classes.tooltip_wrapper : ""}
            ${isFullWidthContainer ? classes.fullWidth : ""} ${
              noOverflow ? classes.no_overflow : ""
            }`}
        >
          {children}
        </div>
      </Tooltip>
    );
  };

  if (smallTooltip) return renderSmallTooltip();

  return renderInfoTooltip();
}
