import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  container: {
    marginLeft: 0,
    height: "100%",
    maxWidth: "none",
    width: "100%",
  },
});

export default useStyles;
