/*
    Use this component in order to reduce redundant UI messages due to server state.
    E.g. show error if data could not be loaded, etc.
 */

import { ClipLoader } from "react-spinners";
import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { ResponsiveContainer } from "recharts";

interface IProps {
  isLoading?: boolean;
  isEmpty?: boolean;
  hasError?: boolean;
  loadingContent?: React.ReactElement;
  children: React.ReactElement;
  wrapperStyle?: string;
}

function ServerDataStatusWrapper({
  isLoading,
  isEmpty,
  hasError,
  loadingContent,
  children,
  wrapperStyle = "",
}: React.PropsWithChildren<IProps>): React.ReactElement {
  const { t } = useTranslation();
  const { classes } = useStyles();
  return (
    <ResponsiveContainer
      width={"100%"}
      height={"100%"}
      className={`${wrapperStyle} ${classes.wrapper}`}
    >
      {isLoading ? (
        loadingContent ? (
          loadingContent
        ) : (
          <div className={classes.spinnerBox}>
            <ClipLoader />
          </div>
        )
      ) : isEmpty ? (
        <div className={classes.textBox}>
          <Typography align="center" color="primary">
            {t("shared.components.LabelWithBox.noData")}
          </Typography>
        </div>
      ) : hasError ? (
        <div className={classes.textBox}>
          <Typography align="center" color="primary">
            {t("shared.components.LabelWithBox.error")}
          </Typography>
        </div>
      ) : (
        children
      )}
    </ResponsiveContainer>
  );
}

export default ServerDataStatusWrapper;
