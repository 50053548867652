import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDoneMutation, useQueryMachinesWithServiceOrders } from "./hooks";
import { selectMachine } from "../../../../redux/reducers/machineSelectReducer";
import { useSelector } from "react-redux";
import { IServiceOrderSettingsTableFormat } from "./api";
import { ClipLoader } from "react-spinners";
import useStyles from "./styles";
import ServiceOrderTable from "./components/serviceOrderTable";
import { selectMachineList } from "../../../../redux/reducers/machineListReducer";
import { IResponsible } from "../../../settings/machine/views/serviceOrders/api/IServiceOrderSettings";
import { useTranslation } from "react-i18next";
import GeorgTextBase, {
  EStyleType,
} from "../../../../shared/components/text/base";
import { EApiState } from "../../../../api";
import {
  calculateDurationUntilNextService,
  calculateUrgencyState,
  getPercentageOfUrgency,
} from "./urgency";
import YesNoDialog from "../../../../shared/components/yesnodialog";
import Toggle from "../../../../shared/components/Toggle";
import { GridColumnVisibilityModel, GridSortModel } from "@mui/x-data-grid";
import ExpandButton from "../../../../shared/components/expandButton";

function getUserName(user: IResponsible): string {
  return `${user.firstName} ${user.lastName}`;
}

function getWidthClassByTableSize(
  isExpanded: boolean,
  isAllMachinesSelected: boolean,
  classes,
) {
  if (!isExpanded && !isAllMachinesSelected) {
    return classes.boxCompact;
  } else if (!isExpanded && isAllMachinesSelected) {
    return classes.boxCompactExtended;
  } else if (isExpanded && !isAllMachinesSelected) {
    return classes.boxExpanded;
  } else {
    return classes.boxExpandedExtended;
  }
}

enum EDisplayState {
  TABLE = "TABLE",
  DONE_DIALOG = "DONE_DIALOG",
}

export default function ServiceOrdersView(): React.ReactElement {
  const machineId = useSelector(selectMachine);
  const [displayState, setDisplayState] = useState<EDisplayState>(
    EDisplayState.TABLE,
  );
  const [orderToFinishId, setOrderToFinishId] = useState<string>("");
  const [versionForFinish, setVersionForFinish] = useState<string>("");
  const machines = useSelector(selectMachineList);
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [isAllMachinesSelected, setIsAllMachinesSelected] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { state: doneMutationState, doneMutation } = useDoneMutation();
  const machineIds = useMemo(() => {
    if (isAllMachinesSelected) {
      return machines.map((m) => m.id);
    } else {
      return [machineId];
    }
  }, [machineId, isAllMachinesSelected]);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "urgencyPercentage", sort: "desc" },
  ]);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      machineName: isAllMachinesSelected,
      description: isExpanded,
      lastService: isExpanded,
      interval: isExpanded,
      currentValue: isExpanded,
      responsibleName: isExpanded,
    });
  const { apiState, machinesWithServiceOrders } =
    useQueryMachinesWithServiceOrders(machineIds);

  const serviceOrdersTableFormat: IServiceOrderSettingsTableFormat[] =
    useMemo(() => {
      if (apiState === EApiState.OK) {
        return machinesWithServiceOrders.flatMap((machineWithOrders) => {
          const {
            machine: { serviceOrder, name: machineName },
          } = machineWithOrders;
          return serviceOrder.map((so) => {
            const {
              id,
              name,
              lastService,
              version,
              interval,
              preWarning,
              component,
              responsible,
              variable,
              category,
              description,
            } = so;
            const urgencyValue = calculateDurationUntilNextService(
              variable.value,
              lastService,
              interval,
            );
            return {
              id,
              machineName,
              name,
              lastService,
              interval,
              categoryName: category.name,
              componentName: component.name,
              description: description,
              variableName: variable.name,
              responsibleName: getUserName(responsible),
              urgency: `${urgencyValue} ${variable.unit}`,
              urgencyState: calculateUrgencyState(
                variable.value,
                lastService,
                interval,
                preWarning,
              ),
              version,
              currentValue: variable.value,
              urgencyPercentage: getPercentageOfUrgency(
                variable.value,
                lastService,
                interval,
              ),
            };
          });
        });
      } else {
        return [];
      }
    }, [machinesWithServiceOrders, apiState]);
  const classByTableSize = getWidthClassByTableSize(
    isExpanded,
    isAllMachinesSelected,
    classes,
  );

  const onDoneCancelCallback = useCallback(() => {
    setOrderToFinishId("");
    setVersionForFinish("");
    setDisplayState(EDisplayState.TABLE);
  }, []);

  const handleSortChange = useCallback(
    (newSortModel: GridSortModel) => {
      setSortModel(newSortModel);
    },
    [sortModel],
  );

  const handleColumnVisibilityChange = useCallback(
    (newColumnVisibilityModel: GridColumnVisibilityModel) => {
      setColumnVisibilityModel(newColumnVisibilityModel);
    },
    [columnVisibilityModel],
  );

  useEffect(() => {
    setColumnVisibilityModel({
      ...columnVisibilityModel,
      description: isExpanded,
      lastService: isExpanded,
      interval: isExpanded,
      currentValue: isExpanded,
      responsibleName: isExpanded,
    });
  }, [isExpanded]);

  useEffect(() => {
    setColumnVisibilityModel({
      ...columnVisibilityModel,
      machineName: isAllMachinesSelected,
    });
  }, [isAllMachinesSelected]);

  return (
    <div className={`${classes.box} ${classByTableSize} `}>
      <Toggle
        isChecked={isAllMachinesSelected}
        onChange={() => {
          setIsAllMachinesSelected(!isAllMachinesSelected);
        }}
      />
      <GeorgTextBase
        type={EStyleType.DARK}
        text={t("taskManager.serviceOrders.allMachinesButton")}
      />
      <div>
        {apiState === EApiState.ERROR && (
          <GeorgTextBase
            type={EStyleType.DARK}
            text={t(
              "taskManager.serviceOrders.errors.queryMachinesWithServiceOrders",
            )}
          />
        )}
        {apiState === EApiState.LOADING && (
          <div className={classes.spinnerBox}>
            <ClipLoader />
          </div>
        )}
        {apiState === EApiState.OK && (
          <div className={classes.tableWithButtonWrapper}>
            <div className={classes.expandButtonWrapper}>
              <ExpandButton
                isExpanded={isExpanded}
                onExpandClick={() => {
                  setIsExpanded(!isExpanded);
                }}
                disabled={false}
              />
            </div>

            <ServiceOrderTable
              isExpanded={isExpanded}
              serviceOrders={serviceOrdersTableFormat}
              onClickDone={(serviceOrderId, version) => {
                setOrderToFinishId(serviceOrderId);
                setVersionForFinish(version);
                setDisplayState(EDisplayState.DONE_DIALOG);
              }}
              isActionDisabled={doneMutationState === EApiState.LOADING}
              onSortChange={handleSortChange}
              sortModel={sortModel}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={handleColumnVisibilityChange}
            />
            <YesNoDialog
              isModalVisible={displayState === EDisplayState.DONE_DIALOG}
              onClose={onDoneCancelCallback}
              title={t("taskManager.serviceOrders.deletionDialog.title")}
              question={t("taskManager.serviceOrders.deletionDialog.question")}
              onCancel={onDoneCancelCallback}
              onSubmit={() => {
                doneMutation(orderToFinishId, versionForFinish);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
