import React, { useMemo } from "react";
import { ShopfloorPieChart } from "../../../../../../../charts";
import ShopfloorValueDisplay from "../../../../../../../../shared/components/valueDisplay/shopfloorValueDisplay";

interface ShopfloorComponentProps {
  value?: number;
  metric?: string;
}
function ShopfloorComponent({
  value,
  metric = "%",
}: ShopfloorComponentProps): React.ReactElement {
  const isPieChart = useMemo(() => {
    return metric === "%";
  }, [metric]);

  return isPieChart ? (
    <ShopfloorPieChart value={value} />
  ) : (
    <ShopfloorValueDisplay value={value} />
  );
}

export default React.memo(ShopfloorComponent);
