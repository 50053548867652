import React from "react";
import Skeleton from "@mui/material/Skeleton";
import HeatMapRowSkeleton from "../heatmapRow/components/skeleton";

// Skeleton component for Heatmap Chart
interface IProps {
  label: string;
}
// skeleton for HeatMap Bar chart
export default function BarChartHeatMapSkeleton({
  label,
}: IProps): React.ReactElement {
  return (
    <div
      key={`skeleton-${label}`}
      style={{
        margin: 0,
        height: `calc(100% - ${5}px)`,
        // paddingLeft: 100,
        display: "flex",
        flexDirection: "row",
      }}
    >
      {/* Y-axis */}
      <Skeleton
        variant="text"
        height={20}
        animation={"wave"}
        width={80}
        style={{
          alignSelf: "center",
          marginLeft: 10,
          marginRight: 10,
        }}
      />
      {/* Heatmap */}
      <HeatMapRowSkeleton height={"100%"} width={`calc((100% - ${100}px))`} />
    </div>
  );
}
