import React, { useCallback, useMemo, useState } from "react";
import { Typography } from "@mui/material";
import { Area, AreaChart, Tooltip, XAxis, ResponsiveContainer } from "recharts";
import useStyles from "./styles";
import chartPartsToHeatMapRowData, {
  IHeatmapRowData,
} from "../../../../../../components/charts/machineStateHeatmapCard/api/transformation/serverResponseToChartFormatTransformer/chartPartsToHeatMapRowData";
import extractChartParts from "./api/extractChartParts/extractChartParts";
import IHeatMapRow from "./api/IHeatMapRow";
import RowTooltip, { ITooltipProps } from "./components/RowTooltip";
import IConfigElement from "../../../../../../config/states/IIndicatorConfigElement";
import { colors } from "../../../../../theme";
import { TFunction } from "i18next";
import { TScaleValueFromServer } from "../../../../../../components/charts/machineStateHeatmapCard/api/transformation/calculateStartEndDateOffset/calculateStartEndDateOffset";

interface IProps {
  label: string;
  rowData: IHeatMapRow;
  // list of data entries, sorted most important last. (more important overlay less important)
  config: IConfigElement[];
  t: TFunction;
  language: string;
  timezone: string;
  showLabels?: boolean;
  displayOnTop?: boolean;
  // if false, a div with fixed width and height is used instead of a responsive container
  useResponsiveContainer?: boolean;
  isStacked?: boolean;
  onMouseOver?: (index: number) => void;
  onMouseLeave?: (index) => void;
  isActive?: boolean;
  index?: number;
  scale?: TScaleValueFromServer;
  hiddenTooltipDataKeys?: string[];
}
function HeatmapRow({
  showLabels,
  rowData,
  config,
  label,
  t,
  language,
  timezone,
  displayOnTop = true,
  isStacked = false,
  useResponsiveContainer = true,
  onMouseOver,
  onMouseLeave,
  isActive = false,
  index = 0,
  scale = "days",
  hiddenTooltipDataKeys = [],
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  const [activeItems, setActiveItems] = useState<number | undefined>(0);

  const areaChartData: IHeatmapRowData[] = useMemo(() => {
    const parts = extractChartParts(rowData.layers, t, language, timezone);
    const configIds = config.map((conf) => conf.id);
    return chartPartsToHeatMapRowData(parts, configIds, scale);
  }, [rowData.layers, config, language, timezone, t, scale]);

  const areaData = useMemo(
    () =>
      areaChartData.map((point: IHeatmapRowData) => {
        return { ...point, background: 1 };
      }),
    [areaChartData],
  );

  const tooltipContent = useCallback(
    (tooltipData) => {
      if (!tooltipData.active || !isActive) return null;
      return (
        <RowTooltip
          config={config}
          tooltipData={tooltipData as ITooltipProps}
          hiddenTooltipDataKeys={isStacked ? [] : hiddenTooltipDataKeys}
        />
      );
    },
    [config, isActive, hiddenTooltipDataKeys, isStacked],
  );

  const hideTooltip = useCallback(() => {
    if (onMouseLeave) onMouseLeave(index);
  }, []);
  const showTooltip = useCallback(() => {
    if (onMouseOver) onMouseOver(index);
  }, []);
  const stackingMethod = useMemo(
    () => (isStacked ? "stacked" : undefined),
    [isStacked],
  );
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onMouseOutHandler = isStacked ? () => {} : hideTooltip;

  const chartContainerProps = useResponsiveContainer
    ? { as: ResponsiveContainer, width: "100%", height: "100%" }
    : { as: "div", width: "100%", height: 30 };

  return (
    <div className={classes.Icontainer} key={label}>
      {showLabels && (
        <div className={classes.legendContainer}>
          <Typography
            className={classes.legendLabel}
            color="primary"
            variant="body2"
          >
            {label}
          </Typography>
        </div>
      )}
      <chartContainerProps.as
        {...chartContainerProps}
        className={classes.chartArea}
      >
        <AreaChart
          className={classes.chart}
          data={areaData}
          width={useResponsiveContainer ? undefined : 290}
          height={useResponsiveContainer ? undefined : 30}
          margin={{
            // top: isXs ? 0 : 5,
            right: 0,
            left: 0,
            // left: isXs ? -20 : 20,
            // bottom: 10,
            bottom: 5,
          }}
          onMouseLeave={hideTooltip}
          // Set stack offset to "expand" to scale stacked areas proportionally and fill the vertical space.
          // stackOffset="expand"
          onMouseMove={(data) => {
            if (data && data.isTooltipActive) {
              const ttPayload = data.activePayload?.filter(
                (payload) =>
                  payload.dataKey !== "background" &&
                  payload.payload[payload.dataKey] === 1,
              );
              setActiveItems(ttPayload?.length);
            }
          }}
        >
          <Tooltip
            wrapperStyle={{
              zIndex: 9999,
              position: "absolute",
              pointerEvents: "auto",
            }}
            content={tooltipContent}
            position={{
              y: displayOnTop
                ? activeItems
                  ? -(45 + activeItems * 20)
                  : 0
                : undefined,
            }}
            isAnimationActive={true}
            // active={false}
            allowEscapeViewBox={{ x: false, y: false }}
          />
          <XAxis
            dataKey="t"
            hide={true}
            tick={false}
            interval="preserveStartEnd"
          />
          {
            /*Background area*/
            [
              <Area
                key={"empty"}
                dataKey={"background"}
                fill={colors.whiteB_3}
                fillOpacity={1}
                name={"background"}
                strokeWidth={0}
                type="stepAfter"
                onMouseOver={showTooltip}
                onMouseOut={onMouseOutHandler}
              />,
            ].concat(
              /* real graph payload */
              config.map((conf: IConfigElement) => {
                return (
                  <Area
                    key={conf.id}
                    dataKey={conf.id}
                    fill={conf.color}
                    fillOpacity={1}
                    name={conf.id}
                    strokeWidth={0}
                    type="stepAfter"
                    stackId={stackingMethod}
                    onMouseOver={showTooltip}
                    onMouseOut={hideTooltip}
                  />
                );
              }),
            )
          }
        </AreaChart>
      </chartContainerProps.as>
    </div>
  );
}

export default React.memo(HeatmapRow);
