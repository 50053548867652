import { Grid } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import useStyles from "./styles";
import useQueryManualStates from "./api/useQueryManualStates";
import ServerDataStatusWrapper from "../../../../shared/components/serverDataStatusWrapper";
import { IManualStateConfigEntryFromServer } from "../../../../api/manualState/IManualStateConfigEntry";
import StateCard from "./components/stateCard";

export default function ManualStateView(): React.ReactElement {
  const { payload, queryState } = useQueryManualStates();

  // only used for demo purpose
  const [pseudoState, setPseudoState] = useState<
    IManualStateConfigEntryFromServer[]
  >([]);

  useEffect(() => {
    setPseudoState(payload);
  }, [payload]);

  const cards = useMemo(
    () =>
      pseudoState
        .filter((entry: IManualStateConfigEntryFromServer) => {
          return !entry.isMarkedAsDeleted && entry.isVisible;
        })
        .sort(
          (
            entry: IManualStateConfigEntryFromServer,
            entry2: IManualStateConfigEntryFromServer,
          ) => {
            return entry.displayPrio - entry2.displayPrio;
          },
        )
        .map((entry: IManualStateConfigEntryFromServer) => {
          return (
            <Grid key={entry.id} xs={12} sm={6} md={4} lg={3} xl={3} item>
              <StateCard
                onClick={(stateId: string) => {
                  setPseudoState(
                    pseudoState.map((s) => {
                      if (s.id === stateId) {
                        return { ...s, isNowActive: !s.isNowActive };
                      } else {
                        return { ...s, isNowActive: false };
                      }
                    }),
                  );
                }}
                id={entry.id}
                isActive={entry.isNowActive}
                name={entry.displayName}
              />
            </Grid>
          );
        }),
    [pseudoState],
  );
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <ServerDataStatusWrapper
        isEmpty={queryState === "EMPTY"}
        hasError={queryState === "ERROR"}
        isLoading={queryState === "LOADING"}
      >
        <Grid container spacing={4}>
          <>{cards}</>
        </Grid>
      </ServerDataStatusWrapper>
    </div>
  );
}
