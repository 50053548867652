import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  container: {
    padding: 0,
    margin: 0,
    border: 0,
    //position: "relative",
    height: "100%",
    width: "100%",
  },
}));

export default useStyles;
