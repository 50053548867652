import { TFunction } from "i18next";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { Check } from "@mui/icons-material";
import { Button } from "@mui/material";
import { ClassNameMap } from "@mui/material/styles";
import StateCell, {
  EDisplayState,
} from "../../../../../../shared/components/georgDataGrid/components/stateCell";
import { TooltipCell } from "../../../../../../shared/components/georgDataGrid/components/tooltipCell";
const defaultProps: Omit<GridColDef, "field"> = {
  headerAlign: "center",
  align: "center",
  sortable: true,
  editable: false,
  type: "string",
  minWidth: 160,
  disableColumnMenu: true,
};

function tooltipCellRender(cellValue: string) {
  return (
    <TooltipCell tooltipText={cellValue}>
      <span>{cellValue}</span>
    </TooltipCell>
  );
}

export default function getColumnConfig(
  t: TFunction,
  classes: ClassNameMap,
  isExpanded: boolean,
  onClickDone: (serviceOrderId: string, version: string) => void,
  isActionDisabled: boolean,
): GridColDef[] {
  return [
    {
      ...defaultProps,
      field: "machineName",
      headerName: t("taskManager.serviceOrders.table.machineName"),
      minWidth: 160,
      renderHeader: () => (
        <h3>{t("taskManager.serviceOrders.table.machineName")}</h3>
      ),
      renderCell: (params: GridCellParams) =>
        tooltipCellRender(params.value as string),
    },
    {
      ...defaultProps,
      field: "name",
      headerName: t("taskManager.serviceOrders.table.name"),
      minWidth: 120,
      renderHeader: () => <h3>{t("taskManager.serviceOrders.table.name")}</h3>,
      renderCell: (params: GridCellParams) =>
        tooltipCellRender(params.value as string),
    },
    {
      ...defaultProps,
      minWidth: 220,
      field: "description",
      headerName: t("taskManager.serviceOrders.table.description"),
      renderHeader: () => (
        <h3>{t("taskManager.serviceOrders.table.description")}</h3>
      ),
      renderCell: (params: GridCellParams) =>
        tooltipCellRender(params.value as string),
    },
    {
      ...defaultProps,
      minWidth: isExpanded ? 160 : 200,
      field: "componentName",
      headerName: t("taskManager.serviceOrders.table.componentName"),
      renderHeader: () => (
        <h3>{t("taskManager.serviceOrders.table.componentName")}</h3>
      ),
      renderCell: (params: GridCellParams) =>
        tooltipCellRender(params.value as string),
    },
    {
      ...defaultProps,
      field: "categoryName",
      headerName: t("taskManager.serviceOrders.table.categoryName"),
      minWidth: isExpanded ? 160 : 200,
      renderHeader: () => (
        <h3>{t("taskManager.serviceOrders.table.categoryName")}</h3>
      ),
      renderCell: (params: GridCellParams) =>
        tooltipCellRender(params.value as string),
    },
    {
      ...defaultProps,
      field: "responsibleName",
      headerName: t("taskManager.serviceOrders.table.responsibleName"),
      renderHeader: () => (
        <h3>{t("taskManager.serviceOrders.table.responsibleName")}</h3>
      ),
      renderCell: (params: GridCellParams) =>
        tooltipCellRender(params.value as string),
    },
    {
      ...defaultProps,
      field: "variableName",
      headerName: t("taskManager.serviceOrders.table.variableName"),
      minWidth: isExpanded ? 160 : 200,
      renderHeader: () => (
        <h3>{t("taskManager.serviceOrders.table.variableName")}</h3>
      ),
      renderCell: (params: GridCellParams) =>
        tooltipCellRender(params.value as string),
    },
    {
      ...defaultProps,
      field: "currentValue",
      headerName: t("taskManager.serviceOrders.table.currentValue"),
      minWidth: 90,
      renderHeader: () => (
        <h3>{t("taskManager.serviceOrders.table.currentValue")}</h3>
      ),
      renderCell: (params: GridCellParams) =>
        tooltipCellRender(`${params.value}`),
    },
    {
      ...defaultProps,
      field: "interval",
      headerName: t("taskManager.serviceOrders.table.interval"),
      minWidth: 90,
      renderHeader: () => (
        <h3>{t("taskManager.serviceOrders.table.interval")}</h3>
      ),
      renderCell: (params: GridCellParams) =>
        tooltipCellRender(`${params.value}`),
    },
    {
      ...defaultProps,
      field: "lastService",
      headerName: t("taskManager.serviceOrders.table.lastService"),
      minWidth: 120,
      renderHeader: () => (
        <h3>{t("taskManager.serviceOrders.table.lastService")}</h3>
      ),
      renderCell: (params: GridCellParams) =>
        tooltipCellRender(`${params.value}`),
    },
    {
      ...defaultProps,
      minWidth: 130,
      field: "urgencyPercentage",
      headerName: t("taskManager.serviceOrders.table.urgency"),
      renderHeader: () => (
        <h3>{t("taskManager.serviceOrders.table.urgency")}</h3>
      ),
      valueGetter: (_row, value) => {
        const { urgency, urgencyPercentage } = value;
        const percentageStr = isExpanded
          ? `(${parseFloat(urgencyPercentage.toFixed(2)) * 100}%)`
          : "";
        return `${urgency} ${percentageStr}`;
      },
      renderCell: (params) => {
        const urgencyState = params.row.urgencyState as EDisplayState;
        // const urgencyValue = params.row.urgency as string;
        const v = params.value;
        /*const percentageStr = isExpanded
          ? `(${parseFloat(v.toFixed(2)) * 100}%)`
          : "";*/
        return (
          <StateCell displayState={urgencyState}>
            <>{v}</>
          </StateCell>
        );
      },
    },
    {
      ...defaultProps,
      field: "done",
      headerName: t("taskManager.serviceOrders.table.done"),
      filterable: false,
      sortable: false,
      minWidth: 40,
      renderHeader: () => <h3>{t("taskManager.serviceOrders.table.done")}</h3>,
      renderCell: (params: GridCellParams) => {
        const id = params.id as string;
        const version = params.row.version as string;
        return (
          <div>
            <Button
              disabled={isActionDisabled}
              className={classes.doneButton}
              variant="contained"
              onClick={() => {
                if (!isActionDisabled) {
                  onClickDone(id, version);
                }
              }}
            >
              <Check />
            </Button>
          </div>
        );
      },
    },
  ];
}
