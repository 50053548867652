import React from "react";
import WithToggleableTooltip from "../../../../../components/withToggableTooltip";
import useStyles from "./styles";

interface IProps {
  children: React.ReactElement;
  tooltipText: string;
  description?: string;
  footer?: string;
  fillContent?: boolean;
  isFullWidthContainer?: boolean;
}

export function TooltipCell({
  children,
  tooltipText,
  description = "",
  footer = "",
  fillContent = false,
  isFullWidthContainer = true,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  return (
    <WithToggleableTooltip
      label={tooltipText}
      description={description}
      footer={footer}
      smallTooltip={description.length === 0}
      noOverflow={true}
      centerContent={true}
      placement="bottom-end"
      isFullWidthContainer={isFullWidthContainer}
    >
      <div
        className={`${fillContent ? classes.fillContent : ""} ${
          classes.tooltipCell
        }`}
      >
        {children}
      </div>
    </WithToggleableTooltip>
  );
}
