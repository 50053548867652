import { TFunction } from "i18next";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { Delete, DeleteOutline, Edit, EditOutlined } from "@mui/icons-material";
import IconCell from "../../../../../../../shared/components/georgDataGrid/components/iconCell";
import { Checkbox } from "@mui/material";
import { TooltipCell } from "../../../../../../../shared/components/georgDataGrid/components/tooltipCell";

function tooltipTextCellRender(cellValue: string) {
  return (
    <TooltipCell tooltipText={cellValue}>
      <span>{cellValue}</span>
    </TooltipCell>
  );
}

const defaultProps: Omit<GridColDef, "field"> = {
  headerAlign: "center",
  align: "center",
  sortable: true,
  editable: false,
  type: "string",
  minWidth: 120,
  disableColumnMenu: true,
};

export default function getColumnConfig(
  t: TFunction,
  onRemoveClick: (serviceOrderId: string) => void,
  onChangeActive: (serviceOrderId: string, newIsActive: boolean) => void,
  onEdit: (serviceOrderId: string) => void,
  isMutationLoading: boolean,
): GridColDef[] {
  return [
    {
      ...defaultProps,
      field: "remove",
      headerName: t("settings.serviceOrders.table.delete"),
      filterable: false,
      sortable: false,
      minWidth: 40,

      renderHeader: () => <DeleteOutline />,
      renderCell: (params: GridCellParams) => {
        const id = params.id as string;
        const tooltipText = t("settings.serviceOrders.table.delete") || "";
        return (
          <TooltipCell tooltipText={tooltipText}>
            <IconCell
              disabled={isMutationLoading}
              onClick={() => {
                onRemoveClick(id);
              }}
              icon={Delete}
            />
          </TooltipCell>
        );
      },
    },
    {
      ...defaultProps,
      field: "name",
      headerName: t("settings.serviceOrders.table.name"),
      renderHeader: () => <h3>{t("settings.serviceOrders.table.name")}</h3>,
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return tooltipTextCellRender(cellValue);
      },
    },
    {
      ...defaultProps,
      field: "description",
      headerName: t("settings.serviceOrders.table.description"),
      minWidth: 220,
      renderHeader: () => (
        <h3>{t("settings.serviceOrders.table.description")}</h3>
      ),
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return tooltipTextCellRender(cellValue);
      },
    },
    {
      ...defaultProps,
      field: "componentName",
      headerName: t("settings.serviceOrders.table.componentHeader"),
      filterable: true,
      renderHeader: () => (
        <h3>{t("settings.serviceOrders.table.componentHeader")}</h3>
      ),
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return tooltipTextCellRender(cellValue);
      },
    },
    {
      ...defaultProps,
      field: "categoryName",
      headerName: t("settings.serviceOrders.table.categoryName"),
      filterable: true,
      renderHeader: () => (
        <h3>{t("settings.serviceOrders.table.categoryName")}</h3>
      ),
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return tooltipTextCellRender(cellValue);
      },
    },
    {
      ...defaultProps,
      field: "responsibleName",
      headerName: t("settings.serviceOrders.table.responsibleName"),
      minWidth: 140,
      renderHeader: () => (
        <h3>{t("settings.serviceOrders.table.responsibleName")}</h3>
      ),
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return tooltipTextCellRender(cellValue);
      },
    },
    {
      ...defaultProps,
      field: "variableName",
      headerName: t("settings.serviceOrders.table.variableName"),
      minWidth: 140,
      renderHeader: () => (
        <h3>{t("settings.serviceOrders.table.variableName")}</h3>
      ),
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return tooltipTextCellRender(cellValue);
      },
    },
    {
      ...defaultProps,
      field: "value",
      headerName: t("settings.serviceOrders.table.value"),
      minWidth: 40,
      renderHeader: () => <h3>{t("settings.serviceOrders.table.value")}</h3>,
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return tooltipTextCellRender(cellValue);
      },
    },
    {
      ...defaultProps,
      minWidth: 40,
      field: "interval",
      headerName: t("settings.serviceOrders.table.interval"),
      renderHeader: () => <h3>{t("settings.serviceOrders.table.interval")}</h3>,
      renderCell: (params: GridCellParams) => {
        return tooltipTextCellRender(`${params.value}`);
      },
    },
    {
      ...defaultProps,
      field: "preWarning",
      headerName: t("settings.serviceOrders.table.preWarning"),
      type: "number",
      minWidth: 80,
      renderHeader: () => (
        <h3>{t("settings.serviceOrders.table.preWarning")}</h3>
      ),
      valueGetter: (params: GridCellParams) => {
        if (!params) {
          return "-";
        }
        const val = parseFloat(params as unknown as string);
        return val * 100;
      },
      renderCell: (params: GridCellParams) => {
        const val = params.value as string;
        return tooltipTextCellRender(`${val} %`);
      },
    },
    {
      ...defaultProps,
      field: "isActive",
      headerName: t("settings.serviceOrders.table.isActive"),
      minWidth: 40,
      renderHeader: () => <h3>{t("settings.serviceOrders.table.isActive")}</h3>,
      renderCell: (params: GridCellParams) => {
        const id = params.id as string;
        const isChecked = params.value as boolean;
        return (
          <Checkbox
            disabled={isMutationLoading}
            onChange={() => {
              onChangeActive(id, !isChecked);
            }}
            checked={isChecked}
          />
        );
      },
    },
    {
      ...defaultProps,
      field: "edit",
      headerName: t("settings.serviceOrders.table.edit"),
      filterable: false,
      sortable: false,
      minWidth: 40,
      renderHeader: () => <EditOutlined />,
      renderCell: (params: GridCellParams) => {
        const id = params.id as string;
        const tooltipText = t("settings.serviceOrders.table.edit") || "";
        return (
          <TooltipCell tooltipText={tooltipText}>
            <IconCell
              disabled={isMutationLoading}
              onClick={() => {
                onEdit(id);
              }}
              icon={Edit}
            />
          </TooltipCell>
        );
      },
    },
  ];
}
