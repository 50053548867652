import React, { useContext } from "react";
import { WithTooltip } from "../index";
import GeorgStorageContext from "../../context/GeorgStorageContext";
import { TPlacement } from "../withTooltip";

interface IProps {
  children: JSX.Element;
  description?: string;
  label?: string;
  sublabel?: string;
  footer?: string;
  smallTooltip?: boolean;
  centerContent?: boolean;
  noOverflow?: boolean;
  placement?: TPlacement;
  isFullWidthContainer?: boolean;
  forceEnable?: boolean;
}

export default function WithToggleableTooltip(
  props: IProps,
): React.ReactElement {
  const { children, label, forceEnable } = props;
  const { isTooltipEnabled } = useContext(GeorgStorageContext);
  const isTooltipVisible =
    forceEnable !== undefined ? forceEnable : isTooltipEnabled;
  if (isTooltipVisible && label && label.length > 0) {
    return <WithTooltip {...props}>{children}</WithTooltip>;
  } else {
    return children;
  }
}
