/*
 *
 *   Timebar component which is implemented in the topbar for displaying current time in the selected timezone.
 *
 */

import React, { useMemo } from "react";
import { Grid, Typography } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ScheduleIcon from "@mui/icons-material/Schedule";
// Custom Imports.
import TimeInfo from "./components/timeInfo";

//------------------------------------------------------------------------------------------------------------
// Interfaces & Types.
type StyleObject = {
  dateTimeContainer: string;
  timeContainer: string;
  timeIcon: string;
  timeLabel: string;
};

interface IProps {
  styles: StyleObject;
  timezone: string;
}

function ITimeBar({ styles, timezone }: IProps): React.ReactElement {
  const dateComponent = useMemo(() => {
    return (
      <TimeInfo
        icon={<DateRangeIcon />}
        timezone={timezone}
        isDate
        styles={{
          timeIcon: styles.timeIcon,
          timeLabel: styles.timeLabel,
        }}
      />
    );
  }, [timezone, styles.timeIcon, styles.timeLabel]);
  const timeComponent = useMemo(() => {
    return (
      <TimeInfo
        icon={<ScheduleIcon />}
        timezone={timezone}
        styles={{
          timeIcon: styles.timeIcon,
          timeLabel: styles.timeLabel,
        }}
      />
    );
  }, [timezone, styles.timeIcon, styles.timeLabel]);
  const timeZoneComponent = useMemo(() => {
    return (
      <Typography className={styles.timeLabel} variant="body2">
        {`(${timezone})`}
      </Typography>
    );
  }, [timezone, styles.timeLabel]);

  return (
    <Grid item className={styles.dateTimeContainer}>
      {dateComponent}
      <div className={styles.timeContainer}>
        {timeComponent}
        {timeZoneComponent}
      </div>
    </Grid>
  );
}
export default React.memo(ITimeBar);
