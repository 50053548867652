import { makeStyles } from "tss-react/mui";
import { colors } from "../../../shared/theme";

const useStyles = makeStyles()(() => ({
  invisible: {
    display: "None",
  },
  container: {
    backgroundColor: colors.white_base,
    padding: 16,
  },
  nomargin: {
    margin: 0,
    backgroundColor: "inherit",
  },
  docsearch_container: {
    backgroundColor: colors.white_base,
    padding: 32,
    overflow: "auto",
  },
  contact_container: {
    marginLeft: 0,
    backgroundColor: colors.white_base,
    padding: 0,
    overflow: "auto",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    marginTop: 0,
  },
  "textfield_doc_search > div > input": {
    backgroundColor: colors.white_base,
    boxSizing: "initial",
  },
  textfield_doc_search: {
    flex: 1,
    marginBottom: 16,
  },
  staff_card: {
    display: "inline-block",
    fontFamily: "Titillium Web",
    fontColor: colors.blue_base,
    width: "100%",
    borderRadius: 0,
    verticalAlign: "midday",
    transform: "perspective(1px) translateZ(0)",
    transitionDuration: "0.3s",
    transitionProperty: "transform",
  },
  small_size: {
    minWidth: 300,
  },
  large_size: {
    minWidth: 400,
  },
  staff_card_content: {
    textAlign: "center",
    padding: "0 !important",
  },
  staff_card_gap: {
    paddingBottom: 16,
  },
  staff_card_mail_jumbotron: {
    backgroundColor: colors.blue_base,
    color: colors.white_base,
    padding: 16,
    margin: 16,
  },
  staff_card_buissnescard_jumbotron: {
    backgroundColor: colors.blue_base,
    color: colors.white_base,
    padding: 16,
    margin: 16,
  },
  staff_card_jumbotron_text: {
    color: colors.white_base,
    textDecoration: "none",
  },
  staff_phone_icon: {
    position: "relative",
    top: 16,
    right: 16,
  },
  search_icon: {
    color: colors.white_base,
    backgroundColor: colors.blue_base,
    height: "56px",
    width: "56px",
    borderRadius: "0px",
    "&:hover": {
      color: colors.white_base,
      backgroundColor: colors.blue_base,
    },
    "&:disabled": {
      color: colors.white_base,
      backgroundColor: colors.blue_base,
    },
  },
  search_container: {
    marginLeft: 16,
  },
}));

export default useStyles;
