import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  IServiceOrderSettingsCreate,
  isServiceOrderSettingsUpdate,
  TServiceorderSettingsCreateOrUpdate,
} from "../../api/IServiceOrderSettings";
import GeorgModal from "../../../../../../../shared/components/modal";
import { useTranslation } from "react-i18next";
import IVariable from "../../api/IVariable";
import { ITableUserInfo } from "../../../../../user/userManagement/components/userTable";
import ICategory from "../../api/ICategory";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import SaveCancelBtnPair from "../../../../../../../shared/components/saveCancelBtnPair";
import FormCombobox from "../../../../../../../shared/components/formCombobox";
import IComponent from "../../api/IComponent";
import useStyles from "./styles";
import validation, {
  EValidationResult,
  MAX_CATEGORY_LENGTH,
  MAX_COMPONENT_LENGTH,
  MAX_DESCRIPTION_LENGTH,
  MAX_NAME_LENGTH,
} from "./validation";
import { useKeyPressed } from "../../../../../../../helper";

function getInitialState(
  variables: IVariable[],
  users: ITableUserInfo[],
  categories: ICategory[],
  components: IComponent[],
): IServiceOrderSettingsCreate {
  return {
    name: "",
    interval: 0,
    variableId: variables[0]?.id || "",
    responsibleId: users[0]?.id || "",
    categoryId: categories[0]?.id || "",
    componentId: components[0]?.id || "",
    preWarning: 0,
    isActive: true,
    description: "",
  };
}

interface IProps {
  isModalVisible: boolean;
  onClose: () => void;
  onSubmit: (crudServiceOrder: TServiceorderSettingsCreateOrUpdate) => void;
  serviceOrderToEdit: TServiceorderSettingsCreateOrUpdate | null;
  variables: IVariable[];
  users: ITableUserInfo[];
  categories: ICategory[];
  components: IComponent[];
}

function addCharacterConstraintsToText(
  text: string,
  actChar: number,
  maxChars: number,
): string {
  return actChar === 0 ? text : `${text} (${actChar}/${maxChars})`;
}

// eslint-disable-next-line complexity
export default function ServiceOrderCRUDForm({
  isModalVisible,
  onClose,
  onSubmit,
  serviceOrderToEdit,
  variables,
  users,
  categories,
  components,
}: IProps): React.ReactElement {
  const isCreateMode = serviceOrderToEdit == null;
  const modalRef = useRef<HTMLDivElement>(null);

  const { classes } = useStyles();
  const { t } = useTranslation();
  const [serviceOrderInModal, setServiceOrderInModal] =
    useState<TServiceorderSettingsCreateOrUpdate>(
      getInitialState(variables, users, categories, components),
    );
  const validationResult = useMemo(() => {
    return validation(serviceOrderInModal);
  }, [serviceOrderInModal]);
  useEffect(() => {
    if (!isCreateMode && isServiceOrderSettingsUpdate(serviceOrderToEdit)) {
      setServiceOrderInModal({
        id: serviceOrderToEdit.id,
        name: serviceOrderToEdit.name,
        isActive: serviceOrderToEdit.isActive,
        interval: serviceOrderToEdit.interval,
        description: serviceOrderToEdit.description,
        componentId: serviceOrderToEdit.componentId,
        // we do use a 0 - 100 representation within modal
        preWarning: serviceOrderToEdit.preWarning * 100,
        responsibleId: serviceOrderToEdit.responsibleId,
        categoryId: serviceOrderToEdit.categoryId,
        variableId: serviceOrderToEdit.variableId,
      });
    } else {
      setServiceOrderInModal(
        getInitialState(variables, users, categories, components),
      );
    }
  }, [
    serviceOrderToEdit,
    isCreateMode,
    variables,
    users,
    categories,
    components,
    isServiceOrderSettingsUpdate,
  ]);

  const onSubmitCallback = useCallback(() => {
    if (validationResult === EValidationResult.OK) {
      onSubmit({
        ...serviceOrderInModal,
        // we do use 0-1 representation internally
        preWarning: serviceOrderInModal.preWarning / 100,
      });
    }
  }, [serviceOrderInModal, validationResult]);

  const variablesOptions = useMemo(
    () =>
      variables.map((v) => {
        return { label: v.name, value: v.id };
      }),
    [variables],
  );
  const componentsOptions = useMemo(
    () =>
      components.map((c) => {
        return { label: c.name, value: c.id };
      }),
    [components],
  );
  const categoriesOptions = useMemo(
    () =>
      categories.map((c) => {
        return { label: c.name, value: c.id };
      }),
    [categories],
  );
  const usersOptions = useMemo(
    () =>
      users.map((v) => {
        return { label: `${v.firstName} ${v.lastName}`, value: v.id };
      }),
    [users],
  );
  useKeyPressed("Enter", onSubmitCallback, modalRef.current);

  const nameLength = serviceOrderInModal.name.length;
  const descriptionLength = serviceOrderInModal.description.length;
  const componentLength = serviceOrderInModal.newComponentName?.length || 0;
  const categoryLength = serviceOrderInModal.newCategoryName?.length || 0;
  return (
    <GeorgModal
      isModalVisible={isModalVisible}
      onClose={onClose}
      title={t(
        `settings.serviceOrders.form.${
          isCreateMode ? "createTitle" : "editTitle"
        }`,
      )}
    >
      <div className={classes.container} ref={modalRef}>
        <Grid container spacing={4}>
          <Grid item md={12} xs={12}>
            <TextField
              id="name"
              name="name"
              label={addCharacterConstraintsToText(
                t(`settings.serviceOrders.form.name`),
                nameLength,
                MAX_NAME_LENGTH,
              )}
              fullWidth
              type={"string"}
              variant="standard"
              error={
                validationResult === EValidationResult.NAME_EMPTY ||
                validationResult === EValidationResult.NAME_LENGTH_VIOLATED
              }
              value={serviceOrderInModal.name}
              onChange={(
                event: React.ChangeEvent<
                  HTMLTextAreaElement | HTMLInputElement
                >,
              ) => {
                setServiceOrderInModal({
                  ...serviceOrderInModal,
                  name: event.target.value,
                });
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormCombobox
              hasError={validationResult === EValidationResult.EMPTY_VARIABLE}
              value={serviceOrderInModal.variableId}
              id={"variable"}
              onChange={(variableId) => {
                setServiceOrderInModal({ ...serviceOrderInModal, variableId });
              }}
              label={t(`settings.serviceOrders.form.variableSelection`)}
              options={variablesOptions}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormCombobox
              hasError={
                validationResult === EValidationResult.COMPONENT_EMPTY ||
                validationResult === EValidationResult.COMPONENT_LENGTH_VIOLATED
              }
              freeSolo
              value={
                serviceOrderInModal.componentId ||
                serviceOrderInModal.newComponentName ||
                ""
              }
              id={"component"}
              onChange={(newComponentName: string) => {
                const existingComp = components.find(
                  (c) =>
                    c.id === newComponentName || c.name === newComponentName,
                );
                if (existingComp) {
                  setServiceOrderInModal({
                    ...serviceOrderInModal,
                    componentId: existingComp.id,
                    newComponentName: undefined,
                  });
                } else {
                  setServiceOrderInModal({
                    ...serviceOrderInModal,
                    componentId: undefined,
                    newComponentName: newComponentName,
                  });
                }
              }}
              label={
                serviceOrderInModal.componentId === undefined
                  ? addCharacterConstraintsToText(
                      t(`settings.serviceOrders.form.component`),
                      componentLength,
                      MAX_COMPONENT_LENGTH,
                    )
                  : t(`settings.serviceOrders.form.component`)
              }
              options={componentsOptions}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormCombobox
              hasError={
                validationResult === EValidationResult.EMPTY_CATEGORY ||
                validationResult === EValidationResult.CATEGORY_LENGTH_VIOLATED
              }
              freeSolo
              value={
                serviceOrderInModal.categoryId ||
                serviceOrderInModal.newCategoryName ||
                ""
              }
              id={"category"}
              onChange={(newCategoryNameOrId: string) => {
                const existingCat = categories.find(
                  (c) =>
                    c.id === newCategoryNameOrId ||
                    c.name === newCategoryNameOrId,
                );
                if (existingCat !== undefined) {
                  setServiceOrderInModal({
                    ...serviceOrderInModal,
                    categoryId: existingCat.id,
                    newCategoryName: undefined,
                  });
                } else {
                  setServiceOrderInModal({
                    ...serviceOrderInModal,
                    categoryId: undefined,
                    newCategoryName: newCategoryNameOrId,
                  });
                }
              }}
              label={
                serviceOrderInModal.categoryId === undefined
                  ? addCharacterConstraintsToText(
                      t(`settings.serviceOrders.form.categorySelection`),
                      categoryLength,
                      MAX_CATEGORY_LENGTH,
                    )
                  : t(`settings.serviceOrders.form.categorySelection`)
              }
              options={categoriesOptions}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormCombobox
              hasError={
                validationResult === EValidationResult.RESPONSIBLE_EMPTY
              }
              value={serviceOrderInModal.responsibleId}
              id={"responsibleSelection"}
              onChange={(responsibleId) => {
                setServiceOrderInModal({
                  ...serviceOrderInModal,
                  responsibleId,
                });
              }}
              label={t(`settings.serviceOrders.form.responsibleSelection`)}
              options={usersOptions}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              error={validationResult === EValidationResult.INTERVAL_VIOLATED}
              type={"number"}
              id="interval"
              name="interval"
              label={t(`settings.serviceOrders.form.interval`)}
              fullWidth
              variant="filled"
              value={serviceOrderInModal.interval}
              onChange={(
                event: React.ChangeEvent<
                  HTMLTextAreaElement | HTMLInputElement
                >,
              ) => {
                const interval = Number.parseFloat(event.target.value);
                setServiceOrderInModal({
                  ...serviceOrderInModal,
                  interval,
                });
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              error={validationResult === EValidationResult.PREWARNING_VIOLATED}
              id="preWarning"
              name="preWarning"
              label={t(`settings.serviceOrders.form.preWarning`)}
              fullWidth
              type={"number"}
              variant="filled"
              inputProps={{ min: 0, max: 100 }}
              value={serviceOrderInModal.preWarning}
              onChange={(
                event: React.ChangeEvent<
                  HTMLTextAreaElement | HTMLInputElement
                >,
              ) => {
                const preWarning = Number.parseInt(event.target.value, 10);
                setServiceOrderInModal({
                  ...serviceOrderInModal,
                  preWarning,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={
                validationResult === EValidationResult.DESCRIPTION_EMPTY ||
                validationResult ===
                  EValidationResult.DESCRIPTION_LENGTH_VIOLATED
              }
              id="description"
              name="description"
              label={addCharacterConstraintsToText(
                t(`settings.serviceOrders.form.description`),
                descriptionLength,
                MAX_DESCRIPTION_LENGTH,
              )}
              fullWidth
              variant="standard"
              value={serviceOrderInModal.description}
              onChange={(
                event: React.ChangeEvent<
                  HTMLTextAreaElement | HTMLInputElement
                >,
              ) => {
                setServiceOrderInModal({
                  ...serviceOrderInModal,
                  description: event.target.value,
                });
              }}
            />
          </Grid>
          <Grid className={classes.placeHolderRow} item xs={12}>
            {t(`settings.serviceOrders.form.validation.${validationResult}`)}
          </Grid>
          <Grid item xs={12}>
            <SaveCancelBtnPair
              disabled={validationResult !== EValidationResult.OK}
              onCancelClicked={onClose}
              onSaveClicked={() => {
                if (serviceOrderInModal) {
                  onSubmitCallback();
                }
              }}
            />
          </Grid>
        </Grid>
      </div>
    </GeorgModal>
  );
}
