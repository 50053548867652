import { makeStyles } from "tss-react/mui";
import { colors } from "../../../../../shared/theme";

const useStyles = makeStyles()(() => ({
  shiftConfigBox: {
    backgroundColor: colors.white_base,
    width: 845,
    minHeight: 450,
    padding: "40px",
  },
  spinnerBox: {
    marginTop: 250,
    marginLeft: 365,
  },
}));

export default useStyles;
