import { CSSProperties } from "react";
import colors from "./colors";

// Default styles.
interface IProps {
  elementHeight: number;
  shopfl_machineryContainer: { marginLeft: number; maxWidth: number | string };
  viewsContainer: {
    backgroundColor: string;
    display: string;
    maxWidth: string | number;
    padding: number;
    width: string | number;
  };
  shadow: string;
  noOverflow: CSSProperties;
}

const styles: IProps = {
  elementHeight: 64,
  shadow: "0px 2px 4px rgba(100,100,100,0.5)",
  shopfl_machineryContainer: { marginLeft: 0, maxWidth: "none" },
  viewsContainer: {
    backgroundColor: colors.white_base,
    display: "flex",
    maxWidth: "none",
    padding: 30,
    width: "100%",
  },
  noOverflow: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};

export default styles;
