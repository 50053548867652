import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { IList } from "../../../shared/components";
import BarChartHeatMap from "../../../shared/components/charts/barChartHeatmap";
import useStateListQuery from "./api/useStateListQuery";
import { useSelector } from "react-redux";
import { selectMachine } from "../../../redux/reducers/machineSelectReducer";
import {
  ITimeSelectionState,
  selectTimeSelection,
} from "../../../redux/reducers/timeSelectReducer";
import ServerDataStatusWrapper from "../../../shared/components/serverDataStatusWrapper";
import { useTranslation } from "react-i18next";
import serverFormatToChartFormat from "./api/transformation/serverFormatToChartFormat/serverFormatToChartFormat";
import { selectIndicator } from "../../../redux/reducers/indicatorReducer";
import IHeatMapRow from "../../../shared/components/charts/barChartHeatmap/components/heatmapRow/api/IHeatMapRow";
import IConfigElement from "../../../config/states/IIndicatorConfigElement";
import getIndicatorConfigList from "../../../config/states/getIndicatorConfigList";
import { TScaleValueFromServer } from "./api/transformation/calculateStartEndDateOffset/calculateStartEndDateOffset";
import { Paper } from "@mui/material";
import useStyles from "./styles";
import ExpandButton from "../../../shared/components/expandButton";
import { getTimezone } from "../../../helper/time/timezone";
import ExportButton, { IExportEnum } from "../components/exportButton";
import GeorgStorageContext from "../../../context/GeorgStorageContext";

interface IProps {
  indicatorIds: string[];
  hiddenTooltipDataKeys?: string[];
}

function MachineStateHeatmapCard({
  indicatorIds,
  hiddenTooltipDataKeys,
}: IProps): React.ReactElement {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();
  const selectedMachine = useSelector(selectMachine);
  const timeSelection = useSelector(selectTimeSelection) as ITimeSelectionState;
  const timezone = getTimezone();
  const allIndicators = useSelector(selectIndicator);
  const { isPreviewEnabled } = useContext(GeorgStorageContext);

  const [scaleFromServer, setScaleFromServer] =
    useState<TScaleValueFromServer>("days");
  const [rows, setRows] = useState<IHeatMapRow[]>([]);
  const [isStacked, setIsStacked] = useState<boolean>(false);
  const [triggerExport, setTriggerExport] = useState<IExportEnum>(
    IExportEnum.NONE,
  );

  const config: IConfigElement[] = useMemo(() => {
    return getIndicatorConfigList(allIndicators, indicatorIds);
  }, [allIndicators, indicatorIds]);

  const { stateListResponse, isLoading, hasError, isEmpty } = useStateListQuery(
    indicatorIds,
    timeSelection.currentFilter,
    timezone,
    selectedMachine,
  );

  const memoizedServerFormatToChartFormat = useCallback(
    (response) => {
      return serverFormatToChartFormat(
        t,
        i18n.language,
        timeSelection.currentFilter.startedAfter,
        response,
        timezone,
      );
    },
    [t, i18n.language, timeSelection],
  );

  useEffect(() => {
    if (stateListResponse != null) {
      if (stateListResponse.length > 0 && stateListResponse[0].scale)
        setScaleFromServer(stateListResponse[0].scale);

      setRows(memoizedServerFormatToChartFormat(stateListResponse));
    }
  }, [stateListResponse, memoizedServerFormatToChartFormat]);

  const handleExpandClick = useCallback(
    () => setIsStacked(!isStacked),
    [isStacked],
  );
  const handleExport = (type: IExportEnum) => {
    switch (type) {
      case "csv":
      case "png":
        setTriggerExport(type);
        break;
      default:
        setTriggerExport(IExportEnum.NONE);
    }
  };
  const handleExportHandled = () => {
    setTriggerExport(IExportEnum.NONE);
  };
  return (
    <ServerDataStatusWrapper
      isEmpty={isEmpty && !isLoading}
      hasError={hasError}
    >
      <Paper className={classes.container}>
        <IList
          label={t(
            "dashboard.stacklight.machineStates.machineStateHeatmap.title",
          )}
          labelColor="textPrimary"
          labelVariant="body1"
          actionComponent={
            <>
              <ExportButton
                disabled={
                  isLoading ||
                  triggerExport !== IExportEnum.NONE ||
                  isEmpty ||
                  hasError
                }
                onClick={handleExport}
                isExportPNGDisabled={true} // TODO: remove this line when PNG export is implemented
                itemDisabledTooltipText={t(
                  "dashboard.commonChart.unImplementedFeature",
                )}
              />
              {isPreviewEnabled && (
                <>
                  <div style={{ width: 10 }}> </div>
                  <ExpandButton
                    isExpanded={isStacked}
                    onExpandClick={handleExpandClick}
                    disabled={isLoading || isEmpty || hasError}
                  />
                </>
              )}
            </>
          }
          actionComponentPosition="right"
        />
        <BarChartHeatMap
          config={config}
          rows={rows}
          scale={scaleFromServer}
          isStacked={isStacked}
          locale={i18n.language}
          timezone={timezone}
          isLoading={isLoading}
          triggerExport={triggerExport}
          onExportHandled={handleExportHandled}
          hiddenTooltipDataKeys={hiddenTooltipDataKeys}
          machineId={selectedMachine}
        />
      </Paper>
    </ServerDataStatusWrapper>
  );
}

export default React.memo(MachineStateHeatmapCard);
