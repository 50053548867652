/*
 *
 *   commonPieChart for shopfloor machine card.
 *
 */

import React, { useMemo } from "react";
import { Cell, PieChart, Pie, Label } from "recharts";
import { colors } from "../../../shared/theme";
import useStyles from "./styles";

//------------------------------------------------------------------------------------------------------------
// Interfaces & Types.
interface Props {
  value?: number;
}

//------------------------------------------------------------------------------------------------------------
// Component.
function ShopfloorPieChart({ value }: Props): React.ReactElement {
  const { classes } = useStyles();
  if (value == undefined) return <></>;
  const pieChartColors: string[] = [colors.blue_base, colors.chart_grey_base];
  const chart = useMemo(() => {
    const data = [{ value }, { value: 100 - value }];
    return (
      <PieChart height={64} width={100}>
        <Pie
          data={data}
          dataKey="value"
          startAngle={180}
          cy={50}
          cx={42}
          endAngle={0}
          innerRadius={35}
          outerRadius={45}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={pieChartColors[index]} />
          ))}
          <Label value={`${value}%`} position="centerBottom" fontSize={16} />
        </Pie>
      </PieChart>
    );
  }, [value]); // Dependency array includes only `value` since it's the only prop affecting the chart
  return <div className={classes.shopfloorPieChartWrapper}>{chart}</div>;
}
export default React.memo(ShopfloorPieChart);
