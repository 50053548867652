import _ from "lodash";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { LegendProps } from "recharts";
import { WithTooltip } from "../../../../../../components";

interface IProps {
  chartColors: string[];
  disabled: string[];
  handleClick: (item: string) => void;
  legendData: LegendProps;
}
export default function LineChartLegend({
  chartColors,
  disabled,
  handleClick,
  legendData,
}: IProps): React.ReactElement {
  const { t } = useTranslation();
  const { classes } = useStyles();
  // Indicator to color mapping.
  return (
    <div className={classes.legendContainer}>
      {_.toPairs<string>(chartColors).map(
        (item: string[], index: number): React.ReactElement => {
          let label = "";
          let description = "";
          let sublabel = "";
          let footer = "";
          const payload = legendData.payload;

          if (payload == null || payload[index] == null) {
            return <div key={`${label}-${index}`} />;
          }
          const dataKey = payload[index].value;
          const active = _.includes(disabled, dataKey);
          if (payload[index] != null) {
            const el = payload[index].value as string;
            if (el === "kpi_availability") {
              label = t("common.kpi_availability.label");
              description = t("common.kpi_availability.description");
              sublabel = t("common.kpi_availability.sublabel");
              footer = t("common.kpi_availability.footer");
            }
            if (el === "kpi_productivity") {
              label = t("common.kpi_productivity.label");
              description = t("common.kpi_productivity.description");
              sublabel = t("common.kpi_productivity.sublabel");
              footer = t("common.kpi_productivity.footer");
            }
            if (el === "kpi_performance_ratio") {
              label = t("common.kpi_performance_ratio.label");
              description = t("common.kpi_performance_ratio.description");
              sublabel = t("common.kpi_performance_ratio.sublabel");
              footer = t("common.kpi_performance_ratio.footer");
            }
            if (el === "kpi_quality_ratio") {
              label = t("common.kpi_quality_ratio.label");
              description = t("common.kpi_quality_ratio.description");
              sublabel = t("common.kpi_quality_ratio.sublabel");
              footer = t("common.kpi_quality_ratio.footer");
            }
          }

          return (
            <WithTooltip
              description={description}
              label={label}
              sublabel={sublabel}
              footer={footer}
              key={`${label}-${index}`}
            >
              <div
                className={classes.legendWrapper}
                key={`${label}-div`}
                onClick={() => {
                  handleClick(payload[index].value);
                }}
              >
                <Box
                  key={`${label}-box`}
                  className={classes.legendColorBox}
                  style={{
                    backgroundColor: item[1],
                  }}
                />
                <Typography
                  key={`${label}-label`}
                  align="center"
                  color={active ? "textSecondary" : "primary"}
                  className={`${classes.legendLabel} ${
                    active
                      ? classes.legendLabelActive
                      : classes.legendLabelInactive
                  }`}
                >
                  {label}
                </Typography>
              </div>
            </WithTooltip>
          );
        },
      )}
    </div>
  );
}
