import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// Custom Imports.
import {
  ITimeSelectionState,
  selectTimeSelection,
} from "../../../redux/reducers/timeSelectReducer";
import { selectMachine } from "../../../redux/reducers/machineSelectReducer";
import { selectIndicator } from "../../../redux/reducers/indicatorReducer";
import useKPIPieChartQuery from "../kpiPieChartCard/api/useKPIPieChartQuery";
import LabelWithBoxCard from "../../../shared/components/labelWithBoxCard";
import { getTimezone } from "../../../helper/time/timezone";
import NestedKPIPieChart, {
  IExtendedPieChartDataEntry,
} from "../../../shared/components/charts/nestedKpiPieChart";

//------------------------------------------------------------------------------------------------------------
// Interfaces & Types.
interface IProps {
  icon: React.ReactElement;
  label: string;
  tooltipLabel: string;
  tooltipSublabel: string;
  tooltipDescription: string;
  tooltipFooter: string;
  indicator?: string;
}
//------------------------------------------------------------------------------------------------------------
// Component.
export default function NestedKPIPieChartCard({
  icon,
  indicator,
  label,
  tooltipLabel,
  tooltipSublabel,
  tooltipDescription,
  tooltipFooter,
}: IProps): React.ReactElement {
  const { t } = useTranslation();
  const [indicatorId, setIndicatorId] = React.useState("0");
  const timeSelection: ITimeSelectionState = useSelector(selectTimeSelection);
  const timezone = getTimezone();
  const selectedMachine = useSelector(selectMachine);
  const indicators = useSelector(selectIndicator);
  React.useEffect(() => {
    // Filtern des indicators aus dem Redux State.
    // Zur Steuerung der Abfrage eines Indicators muss der passende Name des Indicators der Komponente übergeben werden.
    const indicatorFound = indicators.filter((item) => {
      return item.indicatorName === indicator;
    })[0];
    if (indicatorFound != null) {
      setIndicatorId(indicatorFound.id);
    }
  }, [timeSelection]);
  const {
    data: pieChartData,
    isLoading,
    isEmpty,
    hasError,
  } = useKPIPieChartQuery<IExtendedPieChartDataEntry>(
    indicatorId,
    selectedMachine,
    timeSelection,
    timezone,
    true,
  );
  //For values < - JavaScript string conversion automatically insert string -

  const loadingText = t("common.kpi_skeleton.description");

  return (
    <LabelWithBoxCard
      icon={icon}
      label={label}
      tooltipLabel={tooltipLabel}
      tooltipSublabel={tooltipSublabel}
      tooltipDescription={isLoading ? loadingText : tooltipDescription}
      tooltipFooter={isLoading ? "" : tooltipFooter}
      isEmpty={isEmpty && !isLoading}
      hasError={hasError}
    >
      <NestedKPIPieChart
        delta={pieChartData.delta}
        outerCircleDataEntries={
          pieChartData.dataEntries as IExtendedPieChartDataEntry[]
        }
        innerCircleDataEntries={
          (pieChartData.innerDataEntries ?? []) as IExtendedPieChartDataEntry[]
        }
        isLoading={isLoading}
      />
    </LabelWithBoxCard>
  );
}
