import React from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";

interface IProps {
  logo: string;
}

//------------------------------------------------------------------------------------------------------------
// Component.
export default function ILogo({ logo }: IProps): React.ReactElement {
  const navigate = useNavigate();
  const { classes } = useStyles();
  return (
    <Grid item className={classes.logoContainer}>
      <img
        src={logo}
        alt="logo"
        className={classes.logo}
        onClick={() => {
          navigate("/");
        }}
      />
    </Grid>
  );
}
