import React, { useCallback, useMemo, useRef, useState } from "react";
import GeorgModal from "../../../../../../shared/components/modal";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import SaveCancelBtnPair from "../../../../../../shared/components/saveCancelBtnPair";
import useStyles from "./styles";
import validation, {
  EValidationResult,
  MAX_DESCRIPTION_LENGTH,
  MAX_NAME_LENGTH,
} from "./validation";
import { useKeyPressed } from "../../../../../../helper";
import JobDateTimeSelection from "../../../../../../shared/components/jobDateTimeSelection";
import { createUtcTimestamp } from "../../../../../../services";
import JobPrioritySelection from "../../../../../../shared/components/jobPrioritySelection";
import {
  EJobPriorityKey,
  TJobPriorityKey,
} from "../../../../../../shared/components/jobPrioritySelection/components/jobPrioritySelection";
import dateToIsoString from "../../../../../../helper/time/formatting/dateToIsoUtils";
import {
  IJobCreateOrUpdate,
  IJobCreateOrUpdateFrontend,
  isJobUpdate,
} from "../../../../../../api/jobs/interfaces/IJobSettings";
import getPriorityValue from "../../../../../../shared/components/jobPrioritySelection/transformPriorityValue";
import { EJobTimeSelection } from "../../../../../../shared/components/jobDateTimeSelection/components/jobTimePeriodSelection";
import { getTimezone } from "../../../../../../helper/time/timezone";
import { ITableUserInfo } from "../../../../../settings/user/userManagement/components/userTable";
import FormCombobox from "../../../../../../shared/components/formCombobox";
import { TFunction } from "i18next";

function getInitialState(
  currentJob: IJobCreateOrUpdate,
): IJobCreateOrUpdateFrontend {
  const timezone = getTimezone();
  if (isJobUpdate(currentJob)) {
    return {
      jobId: currentJob.jobId,
      name: currentJob.name,
      description: currentJob.description,
      priority: currentJob.priority,
      scheduledAt: currentJob.scheduledAt,
      machineId: currentJob.machineId,
      vid: currentJob.vid,
      createdBy: currentJob.createdBy,
      responsibleId: currentJob.responsibleId,
    };
  } else {
    return {
      name: "",
      description: "",
      priority: getPriorityValue(EJobPriorityKey.URGENT),
      scheduledAt: createUtcTimestamp(true, EJobTimeSelection.NOW, timezone),
      machineId: currentJob.machineId,
      createdBy: currentJob.createdBy,
      responsibleId: currentJob.responsibleId,
    };
  }
}

interface IProps {
  isModalVisible: boolean;
  onClose: () => void;
  onSubmit: (crudServiceOrder: IJobCreateOrUpdate) => void;
  jobToEdit: IJobCreateOrUpdate;
  users: ITableUserInfo[];
}

function addCharacterConstraintsToText(
  text: string,
  actChar: number,
  maxChars: number,
): string {
  return actChar === 0 ? text : `${text} (${actChar}/${maxChars})`;
}

export type TValidationResult = `${EValidationResult}`;

function getTranslationKeys(t: TFunction, key = EValidationResult.OK) {
  let validation = "";
  if ((key as TValidationResult) === key) {
    validation = t(`taskManager.jobManagement.form.validation.${key}`);
  }
  return {
    name: t(`taskManager.jobManagement.form.name`),
    description: t(`taskManager.jobManagement.form.description`),
    validation: validation,
    createTitle: t(`taskManager.jobManagement.form.createTitle`),
    editTitle: t(`taskManager.jobManagement.form.editTitle`),
    responsibleSelection: t(`settings.serviceOrders.form.responsibleSelection`),
  };
}

// eslint-disable-next-line complexity
export default function JobCRUDForm({
  isModalVisible,
  onClose,
  onSubmit,
  jobToEdit,
  users,
}: IProps): React.ReactElement {
  const isCreateMode = !jobToEdit.jobId;
  const modalRef = useRef<HTMLDivElement>(null);

  const { classes } = useStyles();
  const { t } = useTranslation();
  const timezone = getTimezone();
  const [jobInModal, setJobInModal] = useState<IJobCreateOrUpdateFrontend>(
    getInitialState(jobToEdit),
  );
  const updateJobField = useCallback(
    (field: keyof IJobCreateOrUpdateFrontend, value: unknown) => {
      setJobInModal((oldState) => ({
        ...oldState,
        [field]: value,
      }));
    },
    [],
  );
  const validationResult = useMemo(() => {
    return validation(jobInModal);
  }, [jobInModal]);

  const useValidationErrors = useMemo(() => {
    return {
      hasNameError:
        validationResult.includes(EValidationResult.NAME_EMPTY) ||
        validationResult.includes(EValidationResult.NAME_LENGTH_VIOLATED),
      hasDescriptionError:
        validationResult.includes(EValidationResult.DESCRIPTION_EMPTY) ||
        validationResult.includes(
          EValidationResult.DESCRIPTION_LENGTH_VIOLATED,
        ),
      hasPriorityError: validationResult.includes(
        EValidationResult.PRIORITY_EMPTY,
      ),
      hasDateError:
        validationResult.includes(EValidationResult.DUE_DATE_EMPTY) ||
        validationResult.includes(EValidationResult.DUE_DATE_VIOLATED),
    };
  }, [validationResult]);

  const usersOptions = useMemo(
    () =>
      users.map((v) => {
        return { label: `${v.firstName} ${v.lastName}`, value: v.id };
      }),
    [users],
  );

  const onSubmitCallback = useCallback(() => {
    if (validationResult.includes(EValidationResult.OK)) {
      const jobToSubmit: Partial<IJobCreateOrUpdate> = { ...jobInModal };
      const nullableKeys = ["jobId", "vid"];
      for (const key in jobInModal) {
        if (jobInModal[key]) {
          jobToSubmit[key] = jobInModal[key];
        } else if (nullableKeys.includes(key) && !isCreateMode) {
          delete jobToSubmit[key];
          // eslint-disable-next-line no-console
          console.error("jobId must be included in update");
          return;
        }
      }
      onSubmit(jobToSubmit as IJobCreateOrUpdate);
    }
  }, [jobInModal, validationResult, isCreateMode, onSubmit]);

  useKeyPressed("Enter", onSubmitCallback, modalRef.current);

  const nameLength = jobInModal.name?.length ?? 0;
  const descriptionLength = jobInModal.description?.length ?? 0;

  return (
    <GeorgModal
      isModalVisible={isModalVisible}
      onClose={onClose}
      title={
        isCreateMode
          ? getTranslationKeys(t).createTitle
          : getTranslationKeys(t).editTitle
      }
    >
      <div className={classes.container} ref={modalRef}>
        <Grid container spacing={4}>
          <Grid item md={12} xs={12}>
            <TextField
              id="name"
              name="name"
              label={addCharacterConstraintsToText(
                getTranslationKeys(t).name,
                nameLength,
                MAX_NAME_LENGTH,
              )}
              fullWidth
              type={"string"}
              variant="standard"
              error={useValidationErrors.hasNameError}
              value={jobInModal.name}
              onChange={(
                event: React.ChangeEvent<
                  HTMLTextAreaElement | HTMLInputElement
                >,
              ) => {
                updateJobField("name", event.target.value);
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <JobPrioritySelection
              initPriority={
                isCreateMode
                  ? EJobPriorityKey.URGENT
                  : EJobPriorityKey.INDIVIDUAL
              }
              initPriorityValue={jobInModal.priority}
              onPriorityChanged={function (
                selectedPriorityKey: TJobPriorityKey,
                newPriority: number,
              ): void {
                updateJobField("priority", newPriority);
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <JobDateTimeSelection
              initPeriod={
                isCreateMode
                  ? EJobTimeSelection.NOW
                  : EJobTimeSelection.individual
              }
              initTime={
                new Date(
                  jobInModal.scheduledAt ??
                    createUtcTimestamp(true, EJobTimeSelection.NOW, timezone),
                )
              }
              onTimeChanged={(dateTimeSelectionKey, newDateStr) => {
                const newValue = newDateStr
                  ? dateToIsoString(newDateStr)
                  : undefined;
                updateJobField("scheduledAt", newValue);
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormCombobox
              value={jobInModal.responsibleId || ""}
              id={"responsibleSelection"}
              onChange={(responsibleId) => {
                updateJobField("responsibleId", responsibleId);
              }}
              label={getTranslationKeys(t).responsibleSelection}
              options={usersOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={useValidationErrors.hasDescriptionError}
              id="description"
              name="description"
              label={addCharacterConstraintsToText(
                getTranslationKeys(t).description,
                descriptionLength,
                MAX_DESCRIPTION_LENGTH,
              )}
              fullWidth
              variant="standard"
              value={jobInModal.description}
              onChange={(
                event: React.ChangeEvent<
                  HTMLTextAreaElement | HTMLInputElement
                >,
              ) => {
                updateJobField("description", event.target.value);
              }}
            />
          </Grid>
          <Grid className={classes.placeHolderRow} item xs={12}>
            {getTranslationKeys(t, validationResult[0]).validation}
          </Grid>
          <Grid item xs={12}>
            <SaveCancelBtnPair
              disabled={validationResult[0] !== EValidationResult.OK}
              onCancelClicked={onClose}
              onSaveClicked={() => {
                if (jobInModal) {
                  onSubmitCallback();
                }
              }}
            />
          </Grid>
        </Grid>
      </div>
    </GeorgModal>
  );
}
