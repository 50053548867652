import React from "react";
import Skeleton from "@mui/material/Skeleton";

interface IProps {
  height: string | number;
  width?: string | number;
}

// Skeleton component for Heatmap row chart
export default function HeatMapRowSkeleton({
  height,
  width = "100%",
}: IProps): React.ReactElement {
  return (
    <Skeleton
      variant="rectangular"
      height={height}
      animation={"wave"}
      width={width}
    />
  );
}
