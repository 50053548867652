import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { colors } from "../../../../shared/theme";
import { Cell, Pie, PieChart } from "recharts";
import useStyles from "./styles";

// Skeleton component for ShopfloorPieChart
export default function ShopfloorPieChartSkeleton(): React.ReactElement {
  const { classes } = useStyles();
  // skeleton Data
  const skeletonData = [{ name: "Group A", value: 400 }];

  return (
    <div>
      <PieChart height={64} width={100}>
        <Pie
          dataKey="value"
          data={skeletonData}
          startAngle={180}
          cy={50}
          cx={42}
          endAngle={0}
          innerRadius={35}
          outerRadius={45}
          isAnimationActive={false}
        >
          {skeletonData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={`${colors.greyB16}${index % 2 ? "99" : "66"}`}
            />
          ))}
        </Pie>
      </PieChart>
      <div className={classes.skeletonText}>
        <Skeleton variant="text" width={40} height={20} animation="wave" />
      </div>
    </div>
  );
}
