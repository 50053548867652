import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import {
  Avatar,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  SvgIconProps,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material/";
import { OverridableStringUnion } from "@mui/types";
import { Variant } from "@mui/material/styles/createTypography";
import { TypographyPropsVariantOverrides } from "@mui/material/Typography/Typography";
import { animated, useTransition } from "react-spring";

//------------------------------------------------------------------------------------------------------------
// Interfaces & Types.
type StyleObject = {
  hiddenIcon?: string;
  listItem?: string;
  label?: string;
  sublabel?: string;
  simpleListItem?: string;
  actionItem?: string;
};

type Colors =
  | "inherit"
  | "initial"
  | "primary"
  | "secondary"
  | "textPrimary"
  | "textSecondary"
  | "error"
  | undefined;

interface IProps {
  actionComponent?: JSX.Element;
  actionComponentPosition?: "left" | "right";
  actionIcon?: React.FunctionComponent;
  align?: "center" | "flex-start" | undefined;
  avatarIcon?: (props: SvgIconProps) => JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  avatarImage?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  button?: any;
  disablePadding?: boolean;
  icon?: JSX.Element;
  iconPosition?: "left" | "right";
  iconToLabelSpace?: number;
  label?: string | null;
  labelColor?: Colors;
  labelVariant?: OverridableStringUnion<
    Variant | "inherit",
    TypographyPropsVariantOverrides
  >;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  route?: string;
  showIcon?: boolean;
  subItems?: JSX.Element;
  sublabel?: string | JSX.Element | null;
  sublabelColor?: Colors;
  sublabelVariant?: OverridableStringUnion<
    Variant | "inherit",
    TypographyPropsVariantOverrides
  >;
  styles?: StyleObject;
  isCollapsed?: boolean;
  isOpen?: boolean;
  isLoading?: boolean;
  isActionComponentCentered?: boolean;
}

//------------------------------------------------------------------------------------------------------------
// Component.
export default function IList({
  actionComponent: ActionComponent,
  actionIcon: ActionIcon,
  actionComponentPosition = "right",
  align = "center",
  avatarIcon: AvatarIcon,
  avatarImage,
  button,
  disablePadding,
  icon: Icon,
  iconPosition = "left",
  iconToLabelSpace = 56,
  label,
  labelColor,
  labelVariant,
  onClick,
  route = "",
  showIcon = true,
  subItems,
  sublabel,
  sublabelColor,
  sublabelVariant,
  styles = {
    hiddenIcon: "",
    listItem: "",
    label: "",
    sublabel: "",
    simpleListItem: "",
    actionItem: "",
  },
  isCollapsed = false,
  isOpen = false,
  isLoading = false,
  isActionComponentCentered = false,
}: IProps): React.ReactElement {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [open, setOpen] = React.useState(isOpen || isCollapsed);
  const isNestedListOpen = useMemo(
    () => open || isCollapsed || isOpen,
    [open, isCollapsed, isOpen],
  );
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };
  const parentNestedlistDisplay = useMemo(() => {
    return isCollapsed ? "none" : "flex";
  }, [isCollapsed]);
  const actionJustifyContent = useMemo(() => {
    return isActionComponentCentered ? "center" : "flex-end";
  }, [isActionComponentCentered]);
  const transitions = useTransition(isNestedListOpen, {
    keys: null,
    from: { opacity: 0, transform: "translateY(-10%)" },
    enter: { opacity: 1, transform: "translateY(0%)" },
    leave: { opacity: 0, transform: "translateY(-10%)" },
    config: { duration: isLoaded ? 200 : 0 },
  });

  const ListItemContent = () => (
    <ListItemText
      classes={{
        primary: styles.label,
        secondary: styles.sublabel,
      }}
      primary={label}
      primaryTypographyProps={{
        color: labelColor,
        variant: labelVariant,
      }}
      secondaryTypographyProps={{
        color: sublabelColor,
        variant: sublabelVariant,
      }}
      secondary={sublabel}
    />
  );
  const ListItemIconWrapper = () => (
    <ListItemIcon
      color="primary"
      style={
        iconToLabelSpace
          ? {
              justifyContent: "start",
              minWidth: iconToLabelSpace,
            }
          : { justifyContent: "start" }
      }
    >
      {showIcon ? Icon : null}
    </ListItemIcon>
  );
  const ListItemContentRight = () => (
    <>
      {Icon && iconPosition === "right" ? (
        <ListItemIcon
          style={
            iconToLabelSpace
              ? {
                  justifyContent: "end",
                  minWidth: iconToLabelSpace,
                }
              : { justifyContent: "end" }
          }
        >
          {showIcon ? Icon : null}
        </ListItemIcon>
      ) : null}
    </>
  );
  const ListItemContentRightWithIcon = () => (
    <>
      <ListItemContentRight />
      {ActionIcon ? (
        <Grid item xs>
          <ListItemSecondaryAction>
            <IconButton>ActionIcon</IconButton>
          </ListItemSecondaryAction>
        </Grid>
      ) : null}
      {ActionComponent && actionComponentPosition === "right" ? (
        <Grid item xs>
          <ListItemSecondaryAction
            className={styles.actionItem}
            style={{
              display: "flex",
              justifyContent: actionJustifyContent,
            }}
          >
            {ActionComponent}
          </ListItemSecondaryAction>
        </Grid>
      ) : null}
    </>
  );
  const AvatarIconOrImage = () => (
    <>
      {AvatarIcon || avatarImage ? (
        <ListItemAvatar
          style={
            iconToLabelSpace
              ? {
                  minWidth: iconToLabelSpace,
                }
              : {}
          }
        >
          {AvatarIcon ? (
            <Avatar>AvatarIcon</Avatar>
          ) : (
            <Avatar alt="Remy Sharp" src={avatarImage} />
          )}
        </ListItemAvatar>
      ) : null}
    </>
  );
  const ListItems = () => (
    <ListItem
      alignItems={align}
      button={button}
      className={styles.listItem}
      key={label}
      onClick={onClick}
      style={disablePadding ? { padding: 0 } : {}}
    >
      {ActionComponent && actionComponentPosition === "left" ? (
        <ListItemIcon>{ActionComponent}</ListItemIcon>
      ) : null}
      {Icon && iconPosition === "left" ? <ListItemIconWrapper /> : null}
      <AvatarIconOrImage />
      {!isCollapsed ? (
        <Grid item xs style={{ overflow: "hidden" }}>
          {isLoading ? (
            <Skeleton variant="text" animation={"wave"} height={18}>
              <ListItemContent />
            </Skeleton>
          ) : (
            <ListItemContent />
          )}
        </Grid>
      ) : null}
      {!isCollapsed ? <ListItemContentRightWithIcon /> : null}
    </ListItem>
  );
  const renderListItem = () => {
    return (
      <Grid container>
        <ListItems />
      </Grid>
    );
  };

  const ListLinkItems = () => (
    <ListItem
      button
      className={styles.listItem}
      component={Link}
      key={label}
      to={route}
    >
      <div className={styles.simpleListItem}>
        {Icon && iconPosition === "left" ? <ListItemIconWrapper /> : null}
        <AvatarIconOrImage />
        {!isCollapsed ? <ListItemContent /> : null}
        {!isCollapsed ? <ListItemContentRight /> : null}
      </div>
    </ListItem>
  );
  const renderListLinkItem = () => {
    return <ListLinkItems />;
  };

  const NestedList = () => (
    <List disablePadding>
      <ListItem
        alignItems={align}
        button
        className={styles.listItem}
        key={label}
        onClick={handleClick}
        style={{ display: parentNestedlistDisplay }}
      >
        {Icon && iconPosition === "left" ? <ListItemIconWrapper /> : null}
        <AvatarIconOrImage />
        {!isCollapsed ? <ListItemContent /> : null}
        {!isCollapsed ? (
          isNestedListOpen ? (
            <ExpandLess />
          ) : (
            <ExpandMore />
          )
        ) : null}
      </ListItem>
      <ListItemContentRight />
      {transitions((Expandstyles, item) =>
        item ? (
          <animated.div style={Expandstyles}>{subItems}</animated.div>
        ) : null,
      )}
    </List>
  );
  const renderNestedList = () => {
    return <NestedList />;
  };

  if (subItems) return renderNestedList();
  if (route !== "") return renderListLinkItem();
  return renderListItem();
}
