import React, { useMemo } from "react";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarFilterButtonProps,
} from "@mui/x-data-grid";

type ToolbarButtonProps = React.ForwardRefExoticComponent<
  GridToolbarFilterButtonProps & React.RefAttributes<HTMLButtonElement>
>;

type ToolbarButton = {
  component: ToolbarButtonProps;
};

function createToolbarButton(Component: ToolbarButtonProps, index: number) {
  return <Component key={index} />;
}

export const CustomToolbarButtons: React.FC = () => {
  const toolbarButtons: ToolbarButton[] = useMemo(
    () => [
      {
        component: GridToolbarDensitySelector as ToolbarButtonProps,
      },
      {
        component: GridToolbarColumnsButton as ToolbarButtonProps,
      },
      {
        component: GridToolbarFilterButton as ToolbarButtonProps,
      },
      {
        component: GridToolbarExport as ToolbarButtonProps,
      },
    ],
    [],
  );
  return (
    <GridToolbarContainer>
      {toolbarButtons.map(({ component }, index) =>
        createToolbarButton(component, index),
      )}
    </GridToolbarContainer>
  );
};
