import { GridCellParams } from "@mui/x-data-grid";
import { TFunction } from "i18next";

export enum ColumnEnum {
  Done = "done",
  Abort = "abort",
  Edit = "edit",
}

export interface tooltipObject {
  tooltipLabel: string;
  tooltipDescription: string;
}

const preTranslateText = "taskManager.jobManagement.table.";

const getTooltip = new Map<
  ColumnEnum,
  (
    wasDone: boolean,
    wasAborted: boolean,
    wasDeleted: boolean,
    t: TFunction,
  ) => tooltipObject
>([
  [
    ColumnEnum.Done,
    (
      wasDone: boolean,
      wasAborted: boolean,
      wasDeleted: boolean,
      t: TFunction,
    ) => {
      const ret: tooltipObject = { tooltipLabel: "", tooltipDescription: "" };
      if (wasDeleted) {
        ret.tooltipLabel = t(`${preTranslateText}isDeleted`);
      } else if (wasAborted) {
        ret.tooltipLabel = t(`${preTranslateText}isAborted`);
      } else if (wasDone) {
        ret.tooltipLabel = t(
          `${preTranslateText}isDone_field.tooltip.row.undo`,
        );
      } else {
        ret.tooltipLabel = t(
          `${preTranslateText}isDone_field.tooltip.row.makeDone`,
        );
      }
      return ret;
    },
  ],
  [
    ColumnEnum.Abort,
    (
      wasDone: boolean,
      wasAborted: boolean,
      wasDeleted: boolean,
      t: TFunction,
    ) => {
      const ret: tooltipObject = { tooltipLabel: "", tooltipDescription: "" };
      if (wasDeleted) {
        ret.tooltipLabel = t(`${preTranslateText}isDeleted`);
      } else if (wasDone) {
        ret.tooltipLabel = t(`${preTranslateText}isDone`);
      } else if (wasAborted) {
        ret.tooltipLabel = t(
          `${preTranslateText}abort_field.tooltip.row.undoAbort`,
        );
        ret.tooltipDescription = t(
          `${preTranslateText}abort_field.tooltip.row.undoAbort1`,
        );
      } else {
        ret.tooltipLabel = t(
          `${preTranslateText}abort_field.tooltip.row.abort`,
        );
      }
      return ret;
    },
  ],
  [
    ColumnEnum.Edit,
    (
      wasDone: boolean,
      wasAborted: boolean,
      wasDeleted: boolean,
      t: TFunction,
    ) => {
      const ret: tooltipObject = { tooltipLabel: "", tooltipDescription: "" };
      if (wasDeleted) {
        ret.tooltipLabel = t(`${preTranslateText}isDeleted`);
      } else if (wasDone) {
        ret.tooltipLabel = t(`${preTranslateText}isDone`);
      } else if (wasAborted) {
        ret.tooltipLabel = t(`${preTranslateText}isAborted`);
      } else {
        ret.tooltipLabel = t(`${preTranslateText}edit_field.tooltip.row`);
      }
      return ret;
    },
  ],
]);

export const createTooltipText = (
  params: GridCellParams,
  t: TFunction,
  type: ColumnEnum,
) => {
  const { doneAt, abortedAt, deletedAt } = params.row;
  const wasDone = !!doneAt;
  const wasAborted = !!abortedAt;
  const wasDeleted = !!deletedAt;
  const actionFunction = getTooltip.get(type);
  if (actionFunction) {
    return actionFunction(wasDone, wasAborted, wasDeleted, t);
  } else {
    throw new Error(`Unhandled tooltip action: ${type}`);
  }
};
