import { ClassNameMap } from "@mui/material/styles";
import { TFunction } from "i18next";
import { GridCellParams, GridColDef, GridRowId } from "@mui/x-data-grid";
import { Delete } from "@mui/icons-material";
import IconCell from "../../../../../../../shared/components/georgDataGrid/components/iconCell";
import { Checkbox } from "@mui/material";
import UpDownArrowButtons from "../UpDownArrowButtons";
import { TooltipCell } from "../../../../../../../shared/components/georgDataGrid/components/tooltipCell";
const defaultProps: Omit<GridColDef, "field"> = {
  headerAlign: "center",
  align: "center",
  sortable: false,
  disableColumnMenu: true,
};

export default function getColumnConfig(
  styles: ClassNameMap,
  t: TFunction,
  language: string,
  onRowRemove: (id: GridRowId) => void,
  onIsVisibleChange: (id: GridRowId, isVisible: boolean) => void,
  onMoveManualInputUp: (id: GridRowId) => void,
  onMoveManualInputDown: (id: GridRowId) => void,
): GridColDef[] {
  return [
    {
      ...defaultProps,
      field: "remove",
      headerName: t("settings.manualStates.table.delete"),
      sortable: false,
      filterable: false,
      width: 50,
      renderHeader: () => <Delete />,
      renderCell: (params: GridCellParams) => {
        const id = params.id as string;
        const cellValue = t("settings.manualStates.table.delete") || "";
        return (
          <TooltipCell tooltipText={cellValue}>
            <IconCell
              onClick={() => {
                onRowRemove(id);
              }}
              icon={Delete}
            />
          </TooltipCell>
        );
      },
    },
    {
      ...defaultProps,
      field: "displayName",
      headerName: t("settings.manualStates.table.nameHeader"),
      type: "string",
      sortable: true,
      editable: true,
      minWidth: 450,
      renderHeader: () => (
        <h3>{t("settings.manualStates.table.nameHeader")}</h3>
      ),
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return (
          <TooltipCell tooltipText={cellValue}>
            <span>{cellValue}</span>
          </TooltipCell>
        );
      },
    },
    {
      ...defaultProps,
      field: "isVisible",
      sortable: true,
      headerName: t("settings.manualStates.table.isVisibleHeader"),
      type: "boolean",
      minWidth: 150,
      editable: false,
      renderHeader: () => (
        <h3>{t("settings.manualStates.table.isVisibleHeader")}</h3>
      ),
      renderCell: (params) => {
        const isChecked = params.value as boolean;
        return (
          <Checkbox
            onChange={() => onIsVisibleChange(params.id, params.value)}
            checked={isChecked}
          />
        );
      },
    },
    {
      ...defaultProps,
      field: "displayPrio",
      minWidth: 60,
      editable: false,
      filterable: false,
      renderHeader: () => <h3></h3>,
      renderCell: (params) => {
        return (
          <UpDownArrowButtons
            onUpClick={() => onMoveManualInputUp(params.id)}
            onDownClick={() => onMoveManualInputDown(params.id)}
            upTooltipText={t("settings.manualStates.table.moveUp")}
            downTooltipText={t("settings.manualStates.table.moveDown")}
          />
        );
      },
    },
  ];
}
