import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
// custom imports
import GeorgToast from "../../shared/components/georgToast";
import {
  serviceWorkerRegistrationState,
  serviceWorkerUpdated,
} from "../../redux/reducers/serviceWorkerTokenReducer";
import { updateServiceWorkerAndReload } from "../../serviceWorkerRegistration";
import useStyles from "./styles";
import VersionUpdateList from "./versionUpdateList";

export interface ICommitInfo {
  gitCommitHash: string;
  gitCommitMessage: string;
  appVersion?: string;
}

function VersionToast(): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const isServiceWorkerUpdated = useSelector(serviceWorkerUpdated);

  const serviceWorkerTokenRegistration = useSelector(
    serviceWorkerRegistrationState,
  );

  const [openVersionToast, setOpenVersionToast] = useState(false);

  useEffect(() => {
    setOpenVersionToast(isServiceWorkerUpdated);
  }, [isServiceWorkerUpdated]);

  const textComponent = useMemo(
    () => (
      <React.Fragment>
        <div className={classes.cardContainer}>
          <Typography color="secondary" className={classes.titleText}>
            {t("version.toastTitle")}
          </Typography>
          <VersionUpdateList isFetchStarted={isServiceWorkerUpdated} t={t} />
        </div>
      </React.Fragment>
    ),
    [isServiceWorkerUpdated, classes, t],
  );

  // Version Update Toast Handling
  const onHandleUpdate = () => {
    onHandleClose();
    updateServiceWorkerAndReload(serviceWorkerTokenRegistration);
  };

  const onHandleClose = () => {
    setOpenVersionToast(false);
  };

  return (
    <GeorgToast
      isToastOpen={openVersionToast}
      onHandleClose={onHandleClose}
      onHandleUpdate={onHandleUpdate}
      textContent={textComponent}
      actionButtonLabels={{
        actionText: t("version.update"),
        cancelText: t("version.close"),
      }}
    />
  );
}
export default React.memo(VersionToast);
