import React from "react";
import { Grid } from "@mui/material";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface IProps<T> {
  id: string;
  isSidebarOpen?: boolean;
  isSmallView?: boolean;
  children: React.ReactElement;
  cardStyle?: string;
  elementsPerRow: number;
}

export default function CardGridItem<T>({
  id,
  isSmallView,
  isSidebarOpen,
  children,
  cardStyle,
  elementsPerRow,
}: IProps<T>): React.ReactElement {
  const paddingTop = isSmallView ? 16 : 0;
  const itemWidth = 12 / elementsPerRow;
  return (
    <Grid
      key={id}
      item
      xl={isSmallView ? 12 : itemWidth}
      lg={isSidebarOpen ? 12 : itemWidth}
      md={12}
      sm={12}
      xs={12}
      style={{ paddingTop }}
      className={cardStyle}
    >
      <>{children}</>
    </Grid>
  );
}
