import React from "react";
import { IList } from "../index";
import { Card } from "@mui/material";
import { ResponsiveContainer } from "recharts";
import useStyles from "./styles";
import ServerDataStatusWrapper from "../serverDataStatusWrapper";
import WithToggleableTooltip from "../../../components/withToggableTooltip";

interface IProps {
  label: string;
  tooltipLabel: string;
  tooltipDescription: string;
  tooltipSublabel: string;
  tooltipFooter: string;
  icon?: React.ReactElement;
  children: React.ReactElement;
  isDisplayingTextOnly?: boolean;
  isLoading?: boolean;
  hasError?: boolean;
  isEmpty?: boolean;
  actionComponent?: JSX.Element;
}
export default function LabelWithBoxCard({
  label,
  tooltipLabel,
  tooltipSublabel,
  tooltipDescription,
  tooltipFooter,
  icon,
  isDisplayingTextOnly = false,
  children,
  isLoading,
  hasError,
  isEmpty,
  actionComponent = undefined,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  const isCenteringElement =
    isLoading || hasError || isEmpty || isDisplayingTextOnly;
  return (
    <Card className={classes.container}>
      <WithToggleableTooltip
        description={tooltipDescription}
        label={tooltipLabel}
        sublabel={tooltipSublabel}
        footer={tooltipFooter}
      >
        <IList
          label={label}
          labelColor="textPrimary"
          labelVariant="body1"
          icon={icon}
          iconPosition="right"
          styles={{ label: classes.label }}
          iconToLabelSpace={30}
          actionComponent={actionComponent}
        />
      </WithToggleableTooltip>
      <div className={classes.chartContainer}>
        <ResponsiveContainer
          width={"100%"}
          height={"100%"}
          className={
            isCenteringElement ? classes.chartAreaError : classes.chartArea
          }
        >
          <ServerDataStatusWrapper
            isLoading={isLoading}
            isEmpty={isEmpty}
            hasError={hasError}
          >
            {children}
          </ServerDataStatusWrapper>
        </ResponsiveContainer>
      </div>
    </Card>
  );
}
