import IPieChartDataResponse, {
  IPieChartDataResponseEntry,
} from "./IPieChartDataResponse";
import React from "react";
import createKPIRefChartQuery from "./createKPIRefChartQuery";
import { useLazyQuery } from "@apollo/client";
import { IPieChartDataEntry } from "../../../../shared/components/charts/kpiPieChart";
import { colors } from "../../../../shared/theme";
import { ITimeSelectionState } from "../../../../redux/reducers/timeSelectReducer";
import {
  IExtendedPieChartDataEntry,
  ITooltipName,
} from "../../../../shared/components/charts/nestedKpiPieChart";

interface IPieChartQueryResult {
  data: IPieChartData;
  isLoading: boolean;
  hasError: boolean;
  isEmpty: boolean;
}

export interface IPieChartData {
  current: number;
  delta: number;
  dataEntries: IPieChartDataEntry[] | IExtendedPieChartDataEntry[];
  innerDataEntries?: IPieChartDataEntry[] | IExtendedPieChartDataEntry[];
}

/*
  Calculates the partitions of the rendered Piechart.

  /* 1: Basic pie chart -> Function: calculatePieChartFormatData<IPieChartDataEntry[]>

  Description:
  The whole area of the chart consists of 100 units separated by 3 parts (array).

  - The first part and array entry is the current data's starting point.
    if delta > 0 we should use reference, else we use current, because we display the relative difference by delta.
  - The second part is the delta part of the chart. This part is dynamically calculated by checking whether the delta is negative or positive.
  - The empty chart area (no-data) is the 3rd array entry. It is calculated by subtracting the non-empty chart areas (current and delta)
 
/* 2: Extended pie chart -> Function: calculatePieChartFormatData<IExtendedPieChartDataEntry[]>

  Description:
  It prepares data for an "extended" pie chart, creating two pie charts each with two segments.
  - The first pie chart is the outer pie chart that displays the current data, where the first segment is the current data and the second segment is an empty chart area.
    The last segment of the first Pei chart is calculated by subtracting the non-empty chart areas (current).
  - The second pie chart is the inner pie chart that displays the reference data, where the first segment is the reference data and the second segment is an empty chart area.
    The last segment is calculated by subtracting the non-empty chart areas (reference).
*/

// Helper function to create a data entry
function createDataEntry(value: number, color: string, name?: ITooltipName) {
  const entry: Partial<IPieChartDataEntry & IExtendedPieChartDataEntry> = {
    value,
    color,
  };
  if (name !== undefined) {
    entry.name = name;
  }
  return entry;
}

// Helper function to calculate graph size
function calculateGraphSize(basePart, delta) {
  return basePart + Math.abs(delta) > 100 ? 100 : basePart + Math.abs(delta);
}

// Helper function to generate data entries with type safety
function generateDataEntries<
  T extends IPieChartDataEntry | IExtendedPieChartDataEntry,
>(isExtended: boolean, current: number, basePart: number, delta: number): T[] {
  const dataEntries: T[] = [
    createDataEntry(isExtended ? current : basePart, colors.blue_base) as T,
  ];

  if (!isExtended) {
    const diffEntry = createDataEntry(
      Math.abs(delta),
      delta > 0 ? colors.green_base : colors.red_base,
    ) as T;
    dataEntries.push(diffEntry);
  }

  dataEntries.push(
    createDataEntry(
      isExtended ? 100 - current : 100 - calculateGraphSize(basePart, delta),
      colors.whiteB_3,
    ) as T,
  );

  return dataEntries;
}

// Helper function to generate extended data entries with type safety
function generateExtendedDataEntries<
  T extends IPieChartDataEntry | IExtendedPieChartDataEntry,
>(dataEntries: Array<{ value: number; color: string }>): T[] {
  return dataEntries.map((entry, index) => ({
    name: index === 0 ? "current" : "",
    ...entry,
  })) as T[];
}

// Main function to calculate pie chart format data with type safety
function calculatePieChartFormatData<
  T extends IPieChartDataEntry | IExtendedPieChartDataEntry,
>(
  pieChartDataFromServer: IPieChartDataResponseEntry,
  isExtended: boolean,
): IPieChartData {
  const {
    currentAverageValue: current,
    referenceAverageValue: ref,
    delta,
  } = pieChartDataFromServer;
  const basePart = delta > 0 ? ref : current;

  if (!isExtended) {
    return {
      current,
      delta,
      dataEntries: generateDataEntries<T>(isExtended, current, basePart, delta),
    };
  }

  const extendedDataEntries = generateExtendedDataEntries<T>(
    generateDataEntries(isExtended, current, basePart, delta),
  );

  // Usage with the optional 'name' argument:
  const innerDataEntries: T[] = [
    createDataEntry(ref, colors.greyB16, "reference") as T,
    createDataEntry(100 - ref, colors.white_base, "") as T,
  ];

  return {
    current,
    delta,
    dataEntries: extendedDataEntries,
    innerDataEntries,
  };
}

export default function useKPIPieChartQuery<
  T extends IPieChartDataEntry | IExtendedPieChartDataEntry,
>(
  indicatorId: string,
  selectedMachine: string,
  timeSelection: ITimeSelectionState,
  timezone: string,
  isExtended = false,
): IPieChartQueryResult {
  const { query, parameters } = createKPIRefChartQuery(
    indicatorId,
    selectedMachine,
    timeSelection,
    timezone,
  );
  const [doQuery, { data, loading: isLoading, error }] =
    useLazyQuery<IPieChartDataResponse>(query, parameters);
  React.useEffect(() => {
    if (
      timeSelection.referenceFilter.shift !== "" &&
      timeSelection.currentFilter.shift !== ""
    ) {
      doQuery();
    }
  }, [timeSelection, selectedMachine]);
  let pieChartData: IPieChartData = {
    delta: 0,
    current: 0,
    dataEntries: [] as unknown as T[],
  };
  const pieChartDataArrFromServer = data?.getKpiRefChart;
  const isEmpty = !isLoading && pieChartDataArrFromServer == null;
  if (pieChartDataArrFromServer && !isEmpty) {
    const { current, delta, dataEntries, innerDataEntries } =
      calculatePieChartFormatData<T>(pieChartDataArrFromServer, isExtended);
    pieChartData = {
      current,
      delta,
      dataEntries,
      innerDataEntries,
    };
  }

  // Work around check for identifying if query was really erroneous.
  const hasError =
    error != null &&
    error?.message !== "Empty dataset returned." &&
    error?.message !== "Error: Not enough data available";

  return { data: pieChartData, isLoading, hasError, isEmpty };
}
