import { useMediaQuery, useTheme } from "@mui/system";

export function useIsSmallView(isSidebarOpen: boolean): boolean {
  const { breakpoints } = useTheme();

  const isWidthAboveMD = useMediaQuery(breakpoints.up("md"), { noSsr: true });
  const isWidthBelowXL = useMediaQuery(breakpoints.down("xl"), { noSsr: true });
  const isWidthBelowLG = useMediaQuery(breakpoints.down("lg"), { noSsr: true });
  const isWidthLG = isWidthAboveMD && isWidthBelowXL;

  return (isWidthLG && isSidebarOpen) || isWidthBelowLG;
}
