import React from "react";
import Skeleton from "@mui/material/Skeleton";

// Skeleton component for an icon
function IconSkeleton(): React.ReactElement {
  return (
    <Skeleton variant="circular" width={24} height={24} animation="wave" />
  );
}
export default IconSkeleton;
