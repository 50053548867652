import { makeStyles } from "tss-react/mui";
import colors from "../../theme/colors";
import "@fontsource/titillium-web"; // Defaults to weight 400.

/*
  fontsize von header ist gleich wie in table cells aber bold
  selection background color: e8eced
  selection box gelb: DEDC22 //yellow_base
  checkbox blau: 0B4F7D // blueB32
  outline & table separator: EBEBEB // whiteB_8
  HEADER BG: FAFAFA
 */
const borderGrey = "#EBEBEB";
const headerBG = "#FAFAFA";
export const rowSelectionBG = "#E8ECED";

const useStyles = makeStyles()({
  georgDataGridWrapper: {
    "& div.MuiDataGrid-root": {
      color: colors.blue_base,
      border: "1px solid " + borderGrey,
      fontFamily: "Titillium Web,Roboto",
      borderRadius: "0px",
      tableLayout: "fixed",
      whiteSpace: "normal",
      width: "100%",
    },
    "& .MuiDataGrid-cell": {
      textAlign: "center",
    },
    "& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within": {
      outline: "0px solid",
    },
    "& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within": {
      outline: "0px solid",
    },
    "& .MuiDataGrid-columnHeader:focus-within": {
      outline: "0px solid",
    },
    "& .MuiIconButton-colorSecondary:hover": {
      backgroundColor: "transparent",
    },
    "& MuiDataGrid-columnHeader:focus-within": {
      outline: "0px solid",
    },
    "& div.MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& div.MuiDataGrid-columnHeaders": {
      backgroundColor: headerBG,
      borderBottom: "4px solid " + borderGrey,
    },
    "& div.MuiDataGrid-columnHeaderTitleContainer h3": {
      fontSize: "14px",
    },
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: rowSelectionBG,
    },
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-root": {
        fontSize: "14px",
      },
    },
    "& .MuiCheckbox-root": {
      color: colors.blueB32,
    },
    "& .MuiCheckbox-root.Mui-disabled": {
      opacity: 0.3,
    },
    "@media (max-width: 1800px)": {
      "& .MuiDataGrid-cell, .MuiDataGrid-columnHeader": {
        padding: "5px",
      },
    },
    minWidth: "200px",
  },
  customToolbarButtonTooltip: {
    fontSize: "14px",
    backgroundColor: colors.white_base,
    color: colors.blue_base,
    border: "0.6px solid black",
  },
});

export default useStyles;
