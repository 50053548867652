import * as React from "react";
import useStyles from "./styles";
import { Backdrop, Button, Slide, Snackbar, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

export interface IButtonLabels {
  actionText: string;
  cancelText: string;
}
interface IProps {
  isToastOpen: boolean;
  onHandleUpdate: () => void;
  onHandleClose: () => void;
  textContent?: string | React.ReactNode;
  actionButtonLabels: IButtonLabels;
}

const SlideTransition = (props) => {
  return <Slide {...props} direction="down" />;
};

export default function GeorgToast({
  isToastOpen,
  onHandleUpdate,
  onHandleClose,
  textContent,
  actionButtonLabels,
}: IProps): React.ReactElement {
  const { classes } = useStyles();

  const action = (
    <React.Fragment>
      <div className={classes.actionContainer}>
        <div className={classes.firstContainer}>
          <div className={classes.iconContainer}>
            {<ErrorIcon color="warning" />}
          </div>
          <div className={classes.textContainer}>
            {typeof textContent === "string" ? (
              <Typography className={classes.text}>{textContent}</Typography>
            ) : (
              textContent // Assuming parameter is a valid React component
            )}
            <div className={classes.smallSecondContainer}>
              <Button
                color="secondary"
                size="small"
                onClick={() => onHandleClose()}
                className={`${classes.cancelButton} ${classes.button}`}
              >
                {actionButtonLabels.cancelText}
              </Button>
              <Button
                color="secondary"
                size="small"
                onClick={() => onHandleUpdate()}
                className={classes.button}
                sx={{ fontWeight: "bold" }}
              >
                {actionButtonLabels.actionText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  const handleSnackbarClose = () => {
    onHandleClose();
  };

  return (
    <>
      <Backdrop open={isToastOpen} />
      <div className={classes.sncackbarContainer}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={isToastOpen}
          onClose={() => handleSnackbarClose()}
          TransitionComponent={SlideTransition}
          action={action}
          classes={{
            root: classes.styledSnackbar,
          }}
        />
      </div>
    </>
  );
}
