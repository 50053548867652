import React, { useCallback, useMemo } from "react";
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridEditCellProps,
  GridInputRowSelectionModel,
  GridRowModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid";
import {
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
} from "@mui/icons-material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Skeleton } from "@mui/material";
import { GridInitialStateCommunity } from "@mui/x-data-grid/models/gridStateCommunity";
import { useTranslation } from "react-i18next";
import useStyles, { rowSelectionBG } from "./styles";
import { TooltipCell } from "./components/tooltipCell";
import { CustomToolbarButtons } from "./components/customToolbarButtons";

interface IProps<T> {
  rows: T;
  columns: GridColDef[];
  selectionModel?: GridInputRowSelectionModel;
  processRowUpdate?: (
    newRow: GridEditCellProps,
    oldRow: GridEditCellProps,
  ) => GridEditCellProps;
  onSelectionModelChange?: (rowSelectionModel: GridRowSelectionModel) => void;
  onRowClick?: (p: GridRowParams) => void;
  sortModel?: GridSortModel;
  onSortChange?: (newSortModel: GridSortModel) => void;
  columnVisibilityModel?: GridColumnVisibilityModel;
  onColumnVisibilityModelChange?: (
    newGridColumnVisibilityModel: GridColumnVisibilityModel,
  ) => void;
  disableSelectionOnClick?: boolean;
  disableColumnSelector?: boolean;
  disableColumnFilter?: boolean;
  initialState?: GridInitialStateCommunity;
  disableColumnMenu?: boolean;
  isLoading?: boolean;
}

export function GeorgDataGrid<T>({
  rows,
  columns,
  selectionModel,
  processRowUpdate,
  onSelectionModelChange,
  onRowClick,
  onSortChange,
  sortModel,
  columnVisibilityModel,
  onColumnVisibilityModelChange,
  disableSelectionOnClick,
  disableColumnSelector,
  disableColumnFilter,
  initialState,
  disableColumnMenu,
  isLoading = false,
}: IProps<T>): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const localeText = useMemo(
    () => ({
      noRowsLabel: t("shared.georgDataGrid.noData"),
      noResultsOverlayLabel: t("shared.georgDataGrid.noResults"),
      columnMenuUnsort: t("shared.georgDataGrid.columnMenuUnsort"),
      columnMenuSortAsc: t("shared.georgDataGrid.columnMenuSortAsc"),
      columnMenuSortDesc: t("shared.georgDataGrid.columnMenuSortDesc"),
      columnMenuFilter: t("shared.georgDataGrid.columnMenuFilter"),
      columnMenuHideColumn: t("shared.georgDataGrid.columnMenuHideColumn"),
      columnMenuShowColumns: t("shared.georgDataGrid.columnMenuShowColumns"),
      columnHeaderSortIconLabel: "",
      toolbarDensity: t("shared.georgDataGrid.columnMenuDensity"),
      toolbarDensityLabel: t("shared.georgDataGrid.columnMenuDensity"),
      toolbarColumns: t("shared.georgDataGrid.columnMenuColumns"),
      toolbarColumnsLabel: t("shared.georgDataGrid.columnMenuColumns"),
      toolbarFilters: t("shared.georgDataGrid.columnMenuFilter"),
      toolbarFiltersLabel: t("shared.georgDataGrid.columnMenuFilter"),
      toolbarExport: t("shared.georgDataGrid.columnMenuExport"),
      toolbarExportLabel: t("shared.georgDataGrid.columnMenuExport"),
      filterPanelDeleteIconLabel: t("shared.georgDataGrid.delete"),
      toolbarFiltersTooltipShow: t("shared.georgDataGrid.columnMenuShowFilter"),
      toolbarFiltersTooltipHide: t("shared.georgDataGrid.columnMenuHideFilter"),
      columnHeaderFiltersTooltipActive: (count) =>
        count !== 1
          ? `${count.toLocaleString()} ${t("shared.georgDataGrid.columnMenuActiveFilters")}`
          : `${count.toLocaleString()} ${t("shared.georgDataGrid.columnMenuActiveFilter")}`,
      toolbarFiltersTooltipActive: (count) =>
        count !== 1
          ? `${count.toLocaleString()} ${t("shared.georgDataGrid.columnMenuActiveFilters")}`
          : `${count.toLocaleString()} ${t("shared.georgDataGrid.columnMenuActiveFilter")}`,
      filterOperatorContains: t("shared.georgDataGrid.contains"),
      filterOperatorEquals: t("shared.georgDataGrid.equals"),
      filterOperatorStartsWith: t("shared.georgDataGrid.startsWith"),
      filterOperatorEndsWith: t("shared.georgDataGrid.endsWith"),
      filterOperatorIsAnyOf: t("shared.georgDataGrid.isAnyOf"),
      filterOperatorIsEmpty: t("shared.georgDataGrid.isEmpty"),
      filterOperatorIsNotEmpty: t("shared.georgDataGrid.isNotEmpty"),
      filterPanelInputLabel: t("shared.georgDataGrid.value"),
      filterPanelColumns: t("shared.georgDataGrid.columnMenuColumns"),
      filterPanelOperator: t("shared.georgDataGrid.operator"),
      filterPanelInputPlaceholder: t("shared.georgDataGrid.filterValue"),
      filterValueAny: "any",
      filterValueTrue: "true",
      filterValueFalse: "false",
      toolbarExportCSV: t("shared.georgDataGrid.exportCSVLabel"),
      toolbarExportPrint: t("shared.georgDataGrid.exportPrintLabel"),
      toolbarDensityCompact: t("shared.georgDataGrid.densityCompact"),
      toolbarDensityStandard: t("shared.georgDataGrid.densityStandard"),
      toolbarDensityComfortable: t("shared.georgDataGrid.densityComfortable"),
      columnsManagementShowHideAllText: t(
        "shared.georgDataGrid.showHideAllText",
      ),
      columnsManagementReset: t("shared.georgDataGrid.resetText"),
      columnsManagementSearchTitle: t("shared.georgDataGrid.searchText"),
      footerRowSelected: (count) =>
        count !== 1
          ? `${count.toLocaleString()} ${t("shared.georgDataGrid.rowsSelected")}`
          : `${count.toLocaleString()} ${t("shared.georgDataGrid.rowSelected")}`,
    }),
    [t],
  );

  const tableData = useMemo(
    () =>
      isLoading
        ? Array(7)
            .fill(null)
            .map(() => ({ id: Math.random().toString() }))
        : rows,
    [isLoading, rows],
  );
  const tableColumns = useMemo(
    () =>
      isLoading
        ? columns.map((column) => ({
            ...column,
            renderCell: () => {
              return (
                <Skeleton
                  variant="text"
                  animation={"wave"}
                  height={18}
                  width={"100%"}
                />
              );
            },
          }))
        : columns,
    [isLoading, columns],
  );

  const createTooltipIcon = (
    icon: React.ReactNode,
    tooltipTextKey:
      | "columnMenuUnsort"
      | "columnMenuSortAsc"
      | "columnMenuSortDesc",
    opacity?: number,
  ) => {
    const tooltipText = t(`shared.georgDataGrid.${tooltipTextKey}`);
    return (
      <TooltipCell tooltipText={tooltipText}>
        <div style={{ opacity }}>{icon}</div>
      </TooltipCell>
    );
  };

  const unsortedIcon = useCallback(
    () => createTooltipIcon(<ArrowUpwardOutlined />, "columnMenuUnsort", 0.3),
    [t],
  );

  const upwardIcon = useCallback(
    () => createTooltipIcon(<ArrowUpwardOutlined />, "columnMenuSortAsc"),
    [t],
  );

  const downwardIcon = useCallback(
    () => createTooltipIcon(<ArrowDownwardOutlined />, "columnMenuSortDesc"),
    [t],
  );

  const handleSortModelChange = (model: GridSortModel) => {
    if (onSortChange) {
      onSortChange(model);
    }
  };

  const handleColumnVisibilityModelChange = (
    model: GridColumnVisibilityModel,
  ) => {
    if (onColumnVisibilityModelChange) {
      onColumnVisibilityModelChange(model);
    }
  };

  return (
    <div className={classes.georgDataGridWrapper}>
      <DataGrid
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
        initialState={{
          ...initialState,
          pagination: { paginationModel: { pageSize: 7 } },
        }}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        rows={tableData as unknown as GridRowModel[]}
        pageSizeOptions={[7, 15, 50]}
        pagination
        autoHeight
        sx={{
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "5px" },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "10px",
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: "15px",
          },
          "&.MuiDataGrid-row.Mui-selected:hover, .MuiDataGrid-row:hover ": {
            backgroundColor: isLoading ? "unset" : rowSelectionBG,
          },
        }}
        getRowHeight={() => (isLoading ? undefined : "auto")}
        columns={tableColumns}
        rowSelectionModel={selectionModel}
        processRowUpdate={processRowUpdate}
        onRowSelectionModelChange={
          isLoading ? undefined : onSelectionModelChange
        }
        onRowClick={isLoading ? undefined : onRowClick}
        disableRowSelectionOnClick={disableSelectionOnClick || isLoading}
        disableColumnSelector={disableColumnSelector || isLoading}
        disableColumnMenu={disableColumnMenu}
        disableColumnFilter={disableColumnFilter || isLoading}
        hideFooterPagination={isLoading}
        hideFooterSelectedRowCount={isLoading}
        slots={{
          toolbar: isLoading
            ? null
            : (props) => <CustomToolbarButtons {...props} />,
          columnUnsortedIcon: unsortedIcon,
          columnSortedAscendingIcon: upwardIcon,
          columnSortedDescendingIcon: downwardIcon,
          openFilterButtonIcon: FilterAltIcon,
          columnFilteredIcon: FilterAltIcon,
        }}
        slotProps={{
          pagination: {
            labelRowsPerPage: t("shared.georgDataGrid.rowsPerPage"),
            labelDisplayedRows: ({ from, to, count }) =>
              `${from}-${to} ${t("shared.georgDataGrid.of")} ${
                count !== -1 ? count : `> ${to}`
              }`,
          },
          baseTooltip: {
            arrow: true,
            classes: { tooltip: classes.customToolbarButtonTooltip },
          },
        }}
        localeText={localeText}
      />
    </div>
  );
}

export default React.memo(GeorgDataGrid);
