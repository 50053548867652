import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import React from "react";
import { colors } from "../../../../../theme";
import useStyles from "./styles";
import { IExtendedPieChartDataEntry } from "../..";

interface IPositionsProps {
  x: number;
  y: number;
}

interface ICenteredLabelProps {
  labelPosition: IPositionsProps;
  outerRadius: number;
  delta: number;
  actualPoint: IExtendedPieChartDataEntry;
  referencePoint: IExtendedPieChartDataEntry;
}

export default function CustomCenteredLabel({
  labelPosition,
  outerRadius,
  delta,
  actualPoint,
  referencePoint,
}: ICenteredLabelProps): React.ReactElement {
  const { classes } = useStyles();

  const isLargeOuterRadius = outerRadius > 70;
  const iconSize = delta !== 0 ? (isLargeOuterRadius ? 30 : 24) : 0;
  const fontSize = isLargeOuterRadius ? 30 : 24;
  const labelColor =
    delta > 0 ? colors.greenB_16 : delta < 0 ? colors.redB6 : colors.blue_base;
  const textWidthApprox = `${Math.abs(delta)}%`.length * fontSize * 0.6;
  const deltaTextTotalWidth = iconSize + textWidthApprox;

  const actualReferenceFontSize = outerRadius > 70 ? 20 : 18;
  const actualTextWidth =
    `${Math.abs(actualPoint.value)}%`.length * actualReferenceFontSize * 0.3; // An approximation
  const referenceTextWidth =
    `${Math.abs(referencePoint.value)}%`.length * actualReferenceFontSize * 0.3; // An approximation

  const positioningProps = {
    x: labelPosition.x,
    y: labelPosition.y,
    textAnchor: "middle",
  };
  const actualPositioningProps = {
    ...positioningProps,
    y: labelPosition.y - 25,
    fontSize: 20,
  };
  const referencePositioningProps = {
    ...positioningProps,
    y: labelPosition.y + 25,
    fontSize: 20,
  };
  const presentationProps = {
    fill: labelColor,
  };

  const arrowIcon =
    delta > 0 ? (
      <ArrowUpward style={{ color: labelColor }} className={classes.icon} />
    ) : (
      <ArrowDownward style={{ color: labelColor }} className={classes.icon} />
    );

  return (
    <g>
      {/* Adjusted position of foreignObject */}
      {delta !== 0 && (
        <foreignObject
          {...positioningProps}
          width={iconSize}
          height={iconSize}
          style={{
            overflow: "visible",
            transform: `translate(-${deltaTextTotalWidth / 2}px, -${
              iconSize / 2
            }px)`,
          }}
        >
          {arrowIcon}
        </foreignObject>
      )}
      {/* Adjusted position of center text */}
      <text
        {...positioningProps}
        dx={iconSize - deltaTextTotalWidth / 2}
        textAnchor="start"
        dominantBaseline="central"
        className="recharts-text"
        fontSize={fontSize}
        {...presentationProps}
      >
        {`${Math.abs(delta)}%`}
      </text>

      {/* Adjusted positions of circle/text for both actual and reference */}
      {[actualPoint, referencePoint].map((point, index) => (
        <React.Fragment key={index}>
          <circle
            cx={
              index === 0
                ? actualPositioningProps.x
                : referencePositioningProps.x
            }
            cy={positioningProps.y + (index === 0 ? -25 : 25)}
            r={4}
            fill={point.color}
            stroke="none"
            style={{
              overflow: "visible",
              transform: `translateX(-${
                (index === 0 ? actualTextWidth : referenceTextWidth) / 2 + 10
              }px`,
            }}
          />
          <text
            {...(index === 0
              ? actualPositioningProps
              : referencePositioningProps)}
            dx={-((index === 0 ? actualTextWidth : referenceTextWidth) / 2)}
            textAnchor="start"
            dominantBaseline="central"
            className="recharts-text"
            fill={point.color}
            fontSize={actualReferenceFontSize}
          >
            {`${point.value}%`}
          </text>
        </React.Fragment>
      ))}
    </g>
  );
}
