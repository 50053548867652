/*
 *
 *   LineChartCard for machine dashboard and report-view.
 *
 */
import React, { useMemo, useState } from "react";
import useLineChartQuery from "./api/useLineChartQuery";
import { ITimeSlot } from "../../../redux/reducers/timeSelectReducer";
import { useSelector } from "react-redux";
import { selectMachine } from "../../../redux/reducers/machineSelectReducer";
import { selectIndicator } from "../../../redux/reducers/indicatorReducer";
import CommonLineChart, {
  ILineChartData,
} from "../../../shared/components/charts/commonLineChart";
import {
  IKPILineChartData,
  IKPILineChartDataEntry,
} from "./api/reducer/kpiEntryReducer";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import TimeScaleUnitMap, {
  TTimeScaleKey,
} from "../../../api/time/TimeScaleUnitMap";
import LabelWithBoxCard from "../../../shared/components/labelWithBoxCard";
import { indicatorNamesToIndicatorTypes } from "../../../helper/indicator/indicatorNamesToIndicatorIds";
import createLabelForScale from "../../../helper/time/formatting/createLabelForScale";
import getTimeScaleByStartAndEnd from "../../../helper/time/scale/getTimeScaleByStartAndEnd";
import { TIndicatorName } from "../../../config/states/STATE_COLOR_MAP";
import { getTimezone } from "../../../helper/time/timezone";
import LineChartSkeleton from "../../../shared/components/charts/commonLineChart/components/skeleton";
import ExportButton, { IExportEnum } from "../components/exportButton";
import TScale from "../../../api/time/TScale";
import moment from "moment-timezone";
import { IsoDateToTimezoneDate } from "../../../helper/time/formatting/dateToIsoUtils";

interface ILineChartDataTransformationEntry extends IKPILineChartDataEntry {
  timestamp: Date;
  timestampMillis: number;
  name: string;
  label: string;
}
interface IProps {
  label: string;
  tooltipLabel: string;
  tooltipSublabel: string;
  tooltipDescription: string;
  tooltipFooter: string;
  timeSlot: ITimeSlot;
  indicatorNames: TIndicatorName[];
}

// https://stackoverflow.com/questions/16590500/calculate-date-from-week-number-in-javascript
function getDateOfISO8601Week(w: number, y: number): Date {
  const simple = new Date(y, 0, 1 + (w - 1) * 7);
  const dow = simple.getDay();
  const ISOweekStart = simple;
  if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOweekStart;
}

function getDateOfISO8601(
  year: number,
  month: number,
  week: number,
  day: number,
  scale: TScale,
): Date {
  switch (scale) {
    case "DAYS":
      return new Date(year, month, day);
    case "WEEKS":
      return getDateOfISO8601Week(week, year);
    case "MONTHS":
      return new Date(year, month, 1);
    case "YEARS":
      return new Date(year, 0, 1);
    default:
      throw new Error("Unsupported scale");
  }
}

function convertStringTime(t: string): number {
  if (t === "null") {
    return 0;
  } else {
    return parseInt(t, 10);
  }
}

export default function KPILineChartCard({
  label,
  tooltipLabel,
  tooltipSublabel,
  tooltipDescription,
  tooltipFooter,
  timeSlot,
  indicatorNames,
}: IProps): React.ReactElement {
  const { t, i18n } = useTranslation();
  const selectedMachine = useSelector(selectMachine);
  const allIndicators = useSelector(selectIndicator);
  const timezone = getTimezone();
  const [triggerExport, setTriggerExport] = useState<IExportEnum>(
    IExportEnum.NONE,
  );
  const indicators = useMemo(
    () => indicatorNamesToIndicatorTypes(indicatorNames, allIndicators),
    [allIndicators, indicatorNames],
  );
  const scale = useMemo(() => {
    const { startedAfter, endedBefore } = timeSlot;
    return getTimeScaleByStartAndEnd(startedAfter, endedBefore, 100).scale;
  }, [timeSlot, timezone]);

  const isPeriodOnSameDay = useMemo(() => {
    const { startedAfter, endedBefore } = timeSlot;
    // Parse the UTC time strings into DateTime objects and convert to the specified timezone
    const startDateTime = IsoDateToTimezoneDate(startedAfter, timezone);
    const endDateTime = IsoDateToTimezoneDate(endedBefore, timezone);

    // Check if start and end are on the same day
    const isSameDay =
      startDateTime.toFormat("yyyy-MM-dd") ===
      endDateTime.toFormat("yyyy-MM-dd");

    // Check if end time is exactly one hour past midnight of the next day of the start time
    const isOneHourPastMidnight =
      endDateTime.toFormat("yyyy-MM-dd") ===
        startDateTime.plus({ days: 1 }).toFormat("yyyy-MM-dd") &&
      endDateTime.hour <= 1;

    return isSameDay || isOneHourPastMidnight;
  }, [timeSlot, timezone]);

  const { kpiLineChartData, isLoading, hasError, isEmpty } = useLineChartQuery(
    indicators,
    timeSlot,
    selectedMachine,
    scale,
    timezone,
  );
  /*
  transform
  {
  "kpi_productivity": {
    "indicatorTypeId": 47,
    "indicator": "kpi_productivity",
    "lineChartDataEntries": [
      {
        "unit": {
          "dayNameShort": "Sun",
          "monthNameShort": "Mar",
          "week": "11",
          "year": "2022",
          "hour": "6",
          "day": "20",
          "monthIndex": "2"
        },
        "value": 100,
        "indicatorName": "kpi_productivity"
      }, ...]}

     into
      [{
        "name": "Mon",
        "xAxisLabel": "03/14 ",
        "data": {
          "kpi_availability": 1,
          "kpi_productivity": 100,
          "kpi_performance_ratio": 45,
          "kpi_quality_ratio": 100
        }
      }, ...]
   */
  const createTransformedEntry = (
    entry: IKPILineChartDataEntry,
    scale: TScale,
  ): ILineChartDataTransformationEntry => {
    const { day, hour, minute, monthIndex, year, week } = entry.unit;
    const label = createLabelForScale(
      t,
      i18n.language,
      scale,
      isPeriodOnSameDay,
      entry.unit,
    );

    const timestamp =
      scale !== "HOURS"
        ? getDateOfISO8601(
            parseInt(year, 10),
            parseInt(monthIndex, 10),
            parseInt(week, 10),
            parseInt(day, 10),
            scale,
          )
        : moment
            .tz(
              [
                convertStringTime(year),
                convertStringTime(monthIndex),
                convertStringTime(day),
                convertStringTime(hour),
                convertStringTime(minute),
              ],
              timezone,
            )
            .toDate();

    const timestampMillis = timestamp.getTime();
    return {
      ...entry,
      name: entry.unit[TimeScaleUnitMap[scale] as TTimeScaleKey],
      timestamp,
      timestampMillis,
      label,
    };
  };
  const mapChartDataToTransformedData = (
    chartData: IKPILineChartData,
    scale: TScale,
  ): ILineChartDataTransformationEntry[] => {
    return Object.keys(chartData)
      .flatMap((indicatorName: string) => {
        const stateEntries = chartData[indicatorName];
        if (chartData == null || stateEntries == null)
          throw new Error(
            "kpi line chart data == null | stateEntries == null!",
          );
        return stateEntries.lineChartDataEntries.map((entry) =>
          createTransformedEntry(entry, scale),
        );
      })
      .sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
  };
  const createGroupedData = (
    data: ILineChartDataTransformationEntry[],
    indicatorNames: string[],
  ): ILineChartData[] => {
    const grouped = _.groupBy(data, (d) => d.timestamp.getTime());
    const sortedTimestampKeys = Object.keys(grouped).sort(
      (a, b) => parseInt(a, 10) - parseInt(b, 10),
    );

    return sortedTimestampKeys.map((key) => {
      const entries = grouped[key];
      if (!entries || entries.length === 0) {
        throw Error(
          `Illegal State key ${key} not found in ${sortedTimestampKeys}`,
        );
      }

      const first = entries[0];
      let dataObject = {};
      dataObject = Object.fromEntries(
        indicatorNames.map((name) => [name, undefined]),
      );

      entries.forEach((d) => {
        dataObject[d.indicatorName] = d.value;
      });

      return {
        name: first.name,
        xAxisLabel: first.label,
        data: dataObject,
        timestamp: first.timestamp,
        timestampMillis: first.timestampMillis,
      };
    });
  };
  const chartData: ILineChartData[] | undefined = useMemo(() => {
    if (!kpiLineChartData || isLoading || hasError || isEmpty) return;

    const indicatorNames = Object.keys(kpiLineChartData);
    const transformedData = mapChartDataToTransformedData(
      kpiLineChartData,
      scale,
    );
    return createGroupedData(transformedData, indicatorNames);
  }, [kpiLineChartData, isLoading, hasError, isEmpty, scale]);
  const seriesLabel = useMemo(() => {
    return {
      kpi_availability: t("common.kpi_availability.label"),
      kpi_productivity: t("common.kpi_productivity.label"),
      kpi_performance_ratio: t("common.kpi_performance_ratio.label"),
      kpi_quality_ratio: t("common.kpi_quality_ratio.label"),
    };
  }, [t]);
  const order = useMemo(
    () => [
      "kpi_availability",
      "kpi_productivity",
      "kpi_performance_ratio",
      "kpi_quality_ratio",
    ],
    [],
  );

  const handleExport = (type: IExportEnum) => {
    switch (type) {
      case "csv":
      case "png":
        setTriggerExport(type);
        break;
      default:
        setTriggerExport(IExportEnum.NONE);
    }
  };
  const handleExportHandled = () => {
    setTriggerExport(IExportEnum.NONE);
  };

  const loadingText = t("common.kpi_skeleton.description");

  return (
    <LabelWithBoxCard
      label={label}
      tooltipLabel={tooltipLabel}
      tooltipSublabel={tooltipSublabel}
      tooltipDescription={isLoading ? loadingText : tooltipDescription}
      tooltipFooter={isLoading ? "" : tooltipFooter}
      isEmpty={isEmpty && !isLoading}
      hasError={hasError}
      actionComponent={
        <ExportButton
          disabled={
            isLoading ||
            triggerExport !== IExportEnum.NONE ||
            isEmpty ||
            hasError
          }
          onClick={handleExport}
        />
      }
    >
      {isLoading ? (
        <LineChartSkeleton />
      ) : (
        <CommonLineChart
          order={order}
          seriesLabel={seriesLabel}
          data={chartData}
          isLoading={isLoading}
          isEmpty={isEmpty}
          hasError={hasError}
          onExportHandled={handleExportHandled}
          triggerExport={triggerExport}
          machineId={selectedMachine}
          isHourScale={scale === "HOURS"}
        />
      )}
    </LabelWithBoxCard>
  );
}
