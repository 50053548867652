import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Box, Grid } from "@mui/material";
import useStyles from "../../../styles";

// Skeleton component for StatusFooterContent
export default function StatusFooterContentSkeleton(): React.ReactElement {
  const { classes } = useStyles();
  return (
    <Grid container>
      <Grid item>
        <Box className={classes.skeletonStatusContainer}>
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={"100%"}
            animation={"wave"}
          />
        </Box>
      </Grid>
      <Grid item xs>
        <Box className={classes.messageContainer}>
          <div style={{ paddingLeft: 10 }}>
            <Skeleton variant="text" width={"50%"} animation={"wave"} />
            <Skeleton variant="text" width={"60%"} animation={"wave"} />
          </div>
        </Box>
      </Grid>
    </Grid>
  );
}
